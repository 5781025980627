import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  termsAndConditions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "110px",
  },

  termsAndConditionsCard: {
    width: "768px",
  },

  termsAndConditionsCardContent: {
    padding: "35px 25px",
  },

  title: {
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: "0.26px",
    lineHeight: "24px",
    marginBottom: "50px",
  },

  body: {
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    maxHeight: "450px",
    overflow: "auto",
  },
  icon: {
    height: "40px",
    width: "40px",
  },
  answerButton: {
    height: "49px",
    width: "188px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.termsAndConditions}>
      <Card className={classes.termsAndConditionsCard}>
        <IconButton onClick={() => history.push("/")}>
          <ArrowBackIcon className={classes.icon} />
        </IconButton>
        <CardContent className={classes.termsAndConditionsCardContent}>
          <Typography className={classes.title} align="center">
            Politică de Confidentialitate
          </Typography>
          <Typography className={classes.body}>
            <b>A. Introducere</b> <br />
            <br /> Confidențialitatea vizitatorilor website-ului nostru este
            foarte importantă pentru noi și ne luăm angajamentul să o protejăm.
            Această politică explică ce vom face cu informațiile dumneavoastră
            personale. Acordarea consimțământului asupra utilizării
            cookie-urilor în concordanță cu termenii acestei politici atunci
            când vizitați website-ul nostru pentru prima dată, ne permite să
            utilizăm cookie-uri de fiecare dată când vizitați website-ul nostru.
            <br />
            <br />
            <b>B. Credit</b> <br />
            <br /> Acest document a fost creat folosind un șablon de la SEQ
            Legal (seqlegal.com) și modificat de către Website Planet
            (www.websiteplanet.com) <br />
            <br /> <b>C. Colectarea informațiilor personale </b>
            <br />
            <br /> Pot fi colectate, stocate și utilizate următoarele tipuri de
            informații personale: <br />
            <br />
            - informații despre computer, inclusiv adresa IP, locația
            geografică, tipul și versiunea browser-ului și despre sistemul de
            operare; <br />- informații despre vizitele și utilizarea acestui
            website, inclusiv sursa de recomandare, durata vizitei,
            vizualizările paginii și căile de navigare pe website; <br />-
            informații precum adresa dvs. de e-mail, pe care le introduceți
            atunci când vă înregistrați pe website-ul nostru;
            <br /> - informațiile pe care le introduceți atunci când creați un
            profil pe website-ul nostru
            <br /> – de exemplu, numele dvs., pozele de profil, sexul, data
            nașterii, starea civilă, interese și hobby-uri, detalii academice și
            detalii despre ocupație;
            <br /> - informații precum numele și adresa dvs. de e-mail, pe care
            le introduceți pentru a configura abonările la e-mailurile și/sau la
            buletinele noastre informative;
            <br /> - informații pe care le introduceți în timp ce utilizați
            serviciile de pe website-ul nostru;
            <br /> - informații care sunt generate în timp ce utilizați
            website-ul nostru, inclusiv despre când, cât de des și în ce
            circumstanțe îl utilizați;
            <br /> - informații referitoare la orice achiziție efectuată,
            servicii utilizate sau tranzacții pe care le faceți prin intermediul
            website-ului nostru, care pot include numele, adresa, numărul de
            telefon, adresa de e-mail și detaliile cardului bancar;
            <br /> - informațiile pe care le postați pe website-ul nostru cu
            intenția de a le publica pe internet, care pot include numele dvs.
            de utilizator, imaginile de profil și conținutul postărilor;
            <br /> - informații conținute în orice comunicări ne trimiteți prin
            e-mail sau prin intermediul website-ului nostru, inclusiv conținutul
            comunicărilor și metadatele acestora;
            <br /> - orice alte informații personale pe care ni le trimiteți.
            Înainte de a ne divulga informațiile personale ale unei alte
            persoane, trebuie să obțineți consimțământul acelei persoanei atât
            pentru divulgarea, cât și pentru procesarea informațiilor personale
            în conformitate cu această politică.
            <br />
            <br /> <b>D. Utilizarea informațiilor dvs. personale </b> <br />
            <br /> Informațiile personale transmise prin intermediul
            website-ului nostru vor fi utilizate în scopurile specificate de
            această politică sau în paginile respective ale website-ului. Putem
            folosi informațiile dvs. personale pentru: <br />
            <br />- administrarea website-ului și a afacerii noastre;
            <br /> - personalizarea website-ului nostru pentru dvs.; <br />-
            autorizarea utilizării serviciilor disponibile pe website-ul nostru;
            <br />- trimiterea de bunuri cumpărate prin intermediul website-ului
            nostru;
            <br /> - furnizarea serviciilor achiziționate prin intermediul
            website-ului nostru;
            <br /> - trimiterea de rapoarte, facturi și notificări de plată
            către dvs. și colectarea plăților de la dvs.;
            <br /> - trimiterea de comunicări comerciale în vederea informării;
            <br />- trimiterea prin e-mail de notificări solicitate în mod
            expres;
            <br /> - trimiterea buletinului nostru informativ prin e-mail, dacă
            l-ați solicitat (ne puteți informa oricând dacă nu mai doriți
            această comunicare);
            <br /> - trimiterea de comunicări de marketing referitoare la
            afacerea noastră sau la companiile unor terți selectați cu atenție,
            care considerăm că vă pot interesa, prin poștă sau, acolo unde ați
            consimțit în mod specific, prin e-mail sau tehnologii similare (ne
            puteți informa în orice moment dacă nu mai doriți să primiți
            comunicări de marketing);
            <br /> - furnizarea către terți a informațiilor statistice despre
            utilizatorii noștri (acești terți nu vor putea identifica niciun
            utilizator cu ajutorul acestor informații);
            <br /> - abordarea solicitărilor și reclamațiilor făcute de dvs. sau
            despre dvs. referitoare la website-ul nostru;
            <br /> - păstrarea securității website-ului nostru și prevenirea
            fraudelor;
            <br /> - verificarea respectării termenilor și condițiilor care
            reglementează utilizarea website-ului nostru (inclusiv monitorizarea
            mesajelor private trimise prin intermediul serviciului nostru de
            mesagerie privată);
            <br /> - și alte utilizări.
            <br />
            <br /> Dacă trimiteți informații personale în vederea publicării pe
            website-ul nostru, vom publica și vom folosi aceste informații în
            concordanță cu întinderea acordului pe care ni-l furnizați.
            <br />
            <br /> Setările de confidențialitate pot fi utilizate pentru a
            limita publicarea informațiilor dvs. pe website-ul nostru și pot fi
            modificate cu ajutorul comutatoarelor de confidențialitate de pe
            website. <br />
            <br />
            Fără consimțământul dvs. expres, nu vom furniza informațiile dvs.
            personale către terți pentru marketingul direct din partea acestor
            terți sau din partea oricărei alte terțe părți. <br />
            <br />
            <b>E. Divulgarea informațiilor personale </b> <br />
            <br />
            Putem divulga informațiile dvs. personale oricăruia dintre
            angajații, ofițerii, asigurătorii, consilierii profesioniști,
            agenții, furnizorii sau subcontractanții noștri după cum este
            necesar în mod rezonabil pentru scopurile prevăzute în această
            politică. <br />
            <br />
            Putem dezvălui informațiile dvs. personale oricărui membru al
            grupului nostru de companii (acesta include filialele noastre,
            compania-mamă și toate filialele sale), după cum este necesar în mod
            rezonabil pentru scopurile prevăzute în această politică. <br />
            <br />
            Putem divulga informațiile dvs. personale:
            <br />
            <br /> - în măsura în care ni se solicită acest lucru prin lege;
            <br /> - în legătură cu orice procedură legală potențială sau în
            curs;
            <br /> - pentru a stabili, exercita sau apăra drepturile noastre
            legale (inclusiv furnizarea de informații către terți în scopul
            prevenirii fraudelor și reducerea riscului de credit);
            <br /> - către cumpărătorul (sau potențialul cumpărător) al oricărei
            afaceri sau oricărui bun pe care le-am vândut (sau avem în plan să
            îl vindem); și oricărei persoane considerăm în mod rezonabil că s-ar
            putea adresa unei instanțe sau unei alte autorități competente
            pentru divulgarea informațiilor personale în cazul în care, în
            opinia noastră rezonabilă, o astfel de instanță sau autoritate ar
            putea să dispună divulgarea informațiilor personale. <br />
            <br /> Cu excepția situațiilor prevăzute în această politică, nu vom
            furniza informațiile dvs. personale către terți.
            <br />
            <br /> <b>F. Transferuri internaționale de date </b>
            <br />
            <br /> Informațiile pe care le colectăm pot fi stocate, prelucrate
            și transferate între oricare dintre țările în care funcționăm pentru
            a ne permite să utilizăm informațiile în conformitate cu această
            politică.
            <br /> Informațiile colectate pot fi transferate între următoarele
            țări care nu au instituite legi de protecție a datelor personale
            echivalente cu cele în vigoare în Spațiul Economic European: Statele
            Unite ale Americii, Rusia, Japonia, China și India. <br />
            Informațiile personale pe care le publicați pe website-ul nostru sau
            pe care le trimiteți spre publicare pe website-ul nostru pot fi
            disponibile prin internet în întreaga lume. Nu putem împiedica
            utilizarea sau utilizarea greșită a acestor informații de către
            alții.
            <br /> Sunteți de acord în mod expres cu transferurile de informații
            personale descrise în această Secțiune (F).
            <br />
            <br /> <b>G. Păstrarea informațiilor personale</b>
            <br />
            <br /> 1. Această Secțiune G stabilește politicile și procedurile
            noastre de păstrare a datelor, care sunt concepute pentru a ajuta la
            asigurarea respectării obligațiilor noastre legale cu privire la
            păstrarea și ștergerea informațiilor personale. <br />
            Informațiile personale pe care le prelucrăm cu orice scop sau
            scopuri nu vor fi păstrate mai mult decât este necesar pentru acel
            scop sau scopuri.
            <br /> În pofida celorlalte dispoziții din această Secțiune G, vom
            păstra documente (inclusiv documente electronice) care conțin date
            cu caracter personal:
            <br /> în măsura în care ni se solicită acest lucru prin lege;
            <br /> dacă considerăm că documentele pot fi relevante pentru orice
            procedură legală în derulare sau viitoare; și pentru a stabili,
            exercita sau apăra drepturile noastre legale (inclusiv furnizarea de
            informații către terți în scopul prevenirii fraudelor și reducerea
            riscului de credit);
            <br />
            <br />
            <b> H. Securitatea informațiilor dvs. personale</b>
            <br />
            <br /> Vom lua măsuri de precauție tehnice și organizaționale
            rezonabile pentru a preveni pierderea, utilizarea necorespunzătoare
            sau modificarea informațiilor dvs. personale.
            <br /> Vom stoca toate informațiile personale pe care le oferiți pe
            serverele noastre securizate (protejate prin parolă și firewall).
            <br />
            Toate tranzacțiile financiare electronice încheiate prin intermediul
            website-ului nostru vor fi protejate de tehnologia de criptare.
            <br /> Ați luat la cunoștință faptul că transmiterea informațiilor
            pe internet este în mod obișnuit nesigură și nu putem garanta
            securitatea datelor trimise pe internet.
            <br /> Sunteți responsabil(ă) pentru păstrarea confidențialității
            parolei pe care o utilizați pentru accesarea website-ului nostru; nu
            vă vom solicita niciodată parola (cu excepția momentului când vă
            conectați pe website-ul nostru).
            <br />
            <br /> <b> I. Modificări </b> <br />
            <br /> Ne rezervăm dreptul de a actualiza această politică din când
            în când publicând o versiune nouă pe website-ul nostru. Trebuie să
            verificați ocazional această pagină pentru a vă asigura că
            înțelegeți orice modificare adusă acestei politici. Vă putem anunța
            despre modificările aduse acestei politici prin e-mail sau prin
            sistemul de mesagerie privată de pe website-ul nostru.
            <br />
            <br /> <b>J. Drepturile dvs.</b> <br />
            <br /> Ne puteți solicita să vă oferim orice informații personale
            deținem despre dvs.; furnizarea acestor informații va fi supusă
            următorilor termeni: <br />
            <br />
            Gratuit și furnizarea de dovezi suficiente cu privire la identitatea
            dvs. în acest scop, de regulă acceptăm o fotocopie legalizată la
            notar a pașaportului dvs, plus o copie certificată „conform cu
            originalul” a unei facturi de utilități care conține adresa dvs.
            curentă. Putem reține informațiile personale pe care le solicitați
            în măsura permisă de lege.
            <br />
            <br /> Ne puteți solicita în orice moment să nu prelucrăm
            informațiile dvs. personale în scopuri de marketing.
            <br />
            <br /> În practică, de obicei fie vă exprimați acordul expres
            prealabil cu privire la utilizarea informațiilor dvs. personale în
            scopuri de marketing, fie vă vom oferi posibilitatea de a renunța la
            utilizarea informațiilor dvs. personale în scopuri de marketing.
            <br /> <br /> <b> K. Site-uri terțe </b> <br />
            <br /> Site-ul nostru include hyperlink-uri către și detalii despre
            site-uri terțe. Nu deținem niciun control și nu de facem
            responsabili pentru politicile și practicile de confidențialitate
            ale terților.
            <br />
            <br /> <b> L. Actualizarea informațiilor </b> <br />
            <br /> Vă rugăm să ne anunțați dacă informațiile personale pe care
            le deținem despre dvs. trebuie să fie corectate sau actualizate.
            <br /> <br /> <b> M. Cookie-uri </b> <br />
            <br />
            Website-ul nostru folosește cookie-uri. Cookie-ul este un fișier
            care conține un identificator (un șir de litere și numere), care
            este trimis de un server web către un browser și care este stocat de
            acel browser. Identificatorul este apoi trimis înapoi la server de
            fiecare dată când browser-ul solicită o pagină de la acesta.
            Cookie-urile pot fi cookie-uri „persistente” sau cookie-uri „de
            sesiune”: cookie-ul persistent va fi stocat de către browser și va
            rămâne valabil până la data stabilită de expirare, cu excepția
            cazului în care acesta este șters înainte de data de expirare; pe de
            altă parte, cookie-ul de sesiune va expira la sfârșitul sesiunii
            utilizatorului, la închiderea browser-ului. Cookie-urile nu conțin
            de obicei informații care identifică personal un utilizator, dar
            informațiile personale pe care le stocăm despre dvs. pot fi legate
            de informațiile stocate și obținute prin cookie-uri.
            <br />
            <br /> Numele cookie-urilor pe care le folosim pe website-ul nostru
            și scopurile pentru care sunt utilizate acestea pot fi găsite mai
            jos:
            <br /> Folosim Google Analytics și Adwords pe website-ul nostru
            pentru a recunoaște un computer atunci când un utilizator, vizitează
            website-ul / urmărește utilizatorii cât timp navighează pe website /
            permite activarea unui coș de cumpărături pe website / îmbunătățește
            capacitatea de utilizare a website-ului / analizează utilizarea
            website-ului / administrează website-ul / previne fraudele și
            îmbunătățește securitatea website-ului / personalizează website-ul
            pentru fiecare utilizator / direcționează reclame care pot fi de
            interes deosebit pentru utilizator;
            <br /> Majoritatea browser-elor vă permit să refuzați să acceptați
            cookie-uri – de exemplu: în Internet Explorer (versiunea 10) puteți
            bloca cookie-urile utilizând suprascrierea setărilor de manipulare a
            cookie-urilor, disponibile printr-un clic pe „Instrumente”, „Opțiuni
            de Internet”, „Confidențialitate”, apoi „Avansat”; în Firefox
            (versiunea 24) puteți bloca toate cookie-urile făcând clic pe
            „Instrumente”, „Opțiuni”, „Confidențialitate”, selectând „Utilizați
            setări personalizate pentru istoric” din meniul derulant și debifând
            „Acceptați cookie-uri de la website-uri”; și în Chrome (versiunea
            29), puteți bloca toate cookie-urile accesând meniul „Personalizare
            și control”, făcând clic pe „Setări”, „Afișați setările avansate” și
            pe „Setări de conținut”, apoi selectând „Blocați site-urile să
            trimită orice date” la rubrica „Cookie-uri”. Blocarea tuturor
            cookie-urilor va avea un impact negativ asupra utilizabilității
            multor website-uri. Dacă blocați cookie-urile, nu veți putea utiliza
            toate funcțiile de pe website-ul nostru. <br />
            <br />
            Puteți șterge cookie-urile deja stocate pe computer – de exemplu: În
            Internet Explorer (versiunea 10), trebuie să ștergeți manual
            fișierele cookie (puteți găsi instrucțiuni despre cum puteți face
            acest lucru la http://support.microsoft.com/kb/278835 ); În Firefox
            (versiunea 24), puteți șterge cookie-urile făcând clic pe
            „Instrumente”, „Opțiuni” și „Confidențialitate”, apoi selectând
            „Utilizați setări personalizate pentru istoric”, făcând clic pe
            „Afișare cookie-uri”, apoi pe „Ștergeți toate Cookie-urile“; și în
            Chrome (versiunea 29), puteți șterge toate cookie-urile accesând
            meniul „Personalizare și control”, făcând clic pe „Setări”, „Afișați
            setările avansate” și pe „Ștergeți datele de navigare”, apoi
            selectați „Ștergeți cookie-urile și alte date despre website și
            plugin-uri” și faceți clic pe „Ștergeți datele de navigare”.
            Ștergerea cookie-urilor va avea un impact negativ asupra
            utilizabilității multor website-uri.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;
