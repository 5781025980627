import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    height:"100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    marginTop: "110px",
    position: "relative",
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems:"center",
    textAlign:"center",
    width: "976px",
    "@media only screen and (max-width: 1200px)":{
      width:"100%",
    }
  },
  title:{
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: 1,
    marginBottom: "20px",
  },
  subTitle:{
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: 1,
    marginBottom: "25px",
  },
  image: {
    marginTop: 50,
    marginBottom: 20,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
  buttonWrapper:{
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  goBackBtn:{
      margin: "25px 24px 25px 0px",
      height: "49px",
      width: "188px",
      borderRadius: "4px",
      backgroundColor: "#00376A",
  }
}));

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
          <Card className={classes.container}>
            <img
              alt="Not found"
              className={classes.image}
              src="/images/404.svg"
            />
            <Typography className={classes.title}>
              Pagina căutată nu poate fi găsită!
            </Typography>
            <Typography className={classes.subTitle}>
              Mergi înapoi și încearcă altă pagină.
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
              color="primary"
              variant="contained"
              className={classes.goBackBtn}
              onClick={() => history.push("/")}>
              MERGI ÎNAPOI
            </Button></div>
          </Card>
    </div>
  );
};

export default NotFound;
