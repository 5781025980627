import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
    height: "242px",
    minWidth: "220px",
    margin: "16px",
    cursor: "pointer",
    backgroundColor: "#2B3058"
  },
  title: {
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500px",
    lineHeight: "24px",
    width: "188px",
    textOverflow: "ellipsis",
  },
  cardActionArea: {
    height: "173px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "150px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "68px",
    padding: "13px 16px 10px 16px",
    borderTop: "5px solid #FFCB00"
  },
  subtitle: {
    color: "#FFFFFF",
    marginLeft: "0px !important",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
}));

const SimpleChapterCard = ({
  id,
  title,
  subtitle,
  cardBackgroundColor,
  cardBackgroundUrl,
  onClick,
  selected,
}) => {
  const [raised, setRaised] = useState(false);
  const toggleHoverRaised = () => setRaised(!raised);

  const classes = useStyles();
  return (
    <Card
      className={classes.card}
      raised={raised}
      onMouseEnter={toggleHoverRaised}
      onMouseLeave={toggleHoverRaised}
      onClick={() => onClick(id)}
      style={{
        opacity: selected ? 1 : 0.2,
      }}
    >
      <CardActionArea
        className={classes.cardActionArea}
        style={{
          backgroundImage: `url(${cardBackgroundUrl})`,
          backgroundColor: `${cardBackgroundColor}`,
        }}
      />
      <div className={classes.cardActions}>
        <Typography noWrap title={title} className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.subtitle}>
          {subtitle} {subtitle > 1 ? "capitole" : "capitol"}
        </Typography>
      </div>
    </Card>
  );
};

export default SimpleChapterCard;
