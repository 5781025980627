import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import Loading from "../../components/Loading";

import { UserDetails, StatsCard, UserHistory } from "./components";
import { API_BASE_URL } from "../../common/constants";

const useStyles = makeStyles((theme) => ({
  settings: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const QUIZZES_PAGE_SIZE = 10;

const Settings = ({ firebase, makeRequest, decodedToken }) => {
  const classes = useStyles();
  const { uid,displayName } = firebase.auth.currentUser;
  const parsedDisplayName = JSON.parse(displayName);
  const [userDetails, setUserDetails] = useState(null);
  const [userQuizzes, setUserQuizzes] = useState([]);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if(!decodedToken){
      return;
    }

    makeRequest({
      method: 'get',
      url: `${API_BASE_URL}/quiz`,
      params:{
        limit: QUIZZES_PAGE_SIZE,
        offset: offset,
      },
      })
      .then(result => {
        const userQuizzes = result.data.data;
        setUserQuizzes(userQuizzes);
        setCount(result.data.count);
      })
      .catch(error => console.error(error));

    makeRequest({
      method: 'get',
      url: `${API_BASE_URL}/users/me`,
      })
      .then(result => {
        setUserDetails(result.data);
      })
      .catch(error => console.error(error));
  }, [firebase, uid, decodedToken]);

  useEffect(() => {
    if(!decodedToken){
      return;
    }

      makeRequest({
        method: 'get',
        url: `${API_BASE_URL}/quiz`,
        params:{
          limit: QUIZZES_PAGE_SIZE,
          offset: offset,
        },
        })
        .then((result) => {
          if (result?.data?.data.length) {
            if (result.data.count <= QUIZZES_PAGE_SIZE) {
              setUserQuizzes(result?.data?.data);
            } else {
              setUserQuizzes((currentData) => currentData.concat(result.data.data));
            }
            setCount(result?.data?.count);
          }
      });
  }, [offset]);

  if (!userDetails) {
    return <Loading />;
  }

  const fetchMoreQuizzes = () => {
    if (count > offset + QUIZZES_PAGE_SIZE) {
      setOffset(offset + QUIZZES_PAGE_SIZE);
    }
  }

  return (
    <div className={classes.settings}>
      <StatsCard
        firstName={parsedDisplayName.firstName}
        quizzesDone={userDetails.quizzesDone}
        quizzesPassed={userDetails.quizzesPassed}
        accumulatedPoints={parseInt(userDetails.accumulatedPoints)}
      />
      <UserDetails userDetails={userDetails} firebase={firebase} decodedToken={decodedToken} />
      <UserHistory userQuizzes={userQuizzes} fetchMoreQuizzes={fetchMoreQuizzes} count={count}/>
    </div>
  );
};

export default compose(withFirebase, withAuthorization)(Settings);
