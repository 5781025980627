import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  Typography,
  IconButton,
  Button,
  Divider
} from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { useSnackbar } from "notistack";
import { ALLOWED_EMAILS, API_BASE_URL } from "../../common/constants";
import clsx from "clsx";
import Loading from "../../components/Loading";
import EditIcon from '@material-ui/icons/Edit';

require('react-quill/dist/quill.snow.css');

const INITIAL_STATE = {
  isValid: false,
  values: {
    A: "",
    B: "",
    C: "",
    D: "",
    E: "",
    question: "",
    explanation: "",
  },
  touched: {},
  errors: {},
  checked: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    marginTop: "50px",
    width: "768px",
    height: "auto",
    padding: "34px 58px 34px 58px",
  },
  textField: {
    minHeight: "5rem",
    marginBottom: "5px",
    borderRadius: "4px",
  },
  quizIndex:{
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.15',
    color: '#000000',
  },
  quizQuestion:{
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.5',
    color: '#000000',
  },
  editIcon:{
    color:'#1B3058'
  },
  divider:{
    backgroundColor:'#00376A',
    opacity:'25%',
    height: '1px',
    marginTop: '20px'
  },
  formControl: {
    minHeight: "5rem",
  },
  saveButton: {
    height: "49px",
    width: "120px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  cancelButton: {
    height: "49px",
    width: "120px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
    color: "color: rgba(0,0,0,0.74)",
  },
  saveSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const Questions = ({ firebase, decodedToken }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState(INITIAL_STATE);
  const [questions, setQuestions] = useState([]);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [chapterList, setChapterList] = useState([]);

  const escapeHTMLTags = (strInputCode) => {
    // var pattern = @"<(img|a)[^>]*>(?<content>[^<]*)<";
    const pattern = new RegExp('@"<(p|strong|span)[^>]*>(?<content>[^<]*)<"');
    // const pattern = new RegExp('/<\\/?[\\w\\s]*>|<.+[\\W]>/g');
    // const cleanText = strInputCode.replace(/<\/?[^>]+(>|$)/g, "");
    const cleanText = strInputCode.replace(/(<strong>|<\/strong>)/g, "");
    return cleanText;
  }

  const handleEdit = (questionId) => {
    history.push(`/questions/edit/${questionId}`);
  }

  const userEmail = firebase.auth.currentUser.email;

  // console.log("userUid: ", firebase.auth.currentUser.email);


  const handleSearch = () => {
    if(!formState.values.question){
      enqueueSnackbar("Vă rugăm introducți întrebarea pe care doriți să o editați", {
        variant: "error",
      });
      return;
    }

    setBtnDisabled(true);

    axios({
      method: 'get',
      url: `${API_BASE_URL}/questions?filters[question]=${formState.values.question}`,
      headers: {Authorization: `Bearer ${decodedToken}`}})
      .then(result => {
        setQuestions(result.data);
        setBtnDisabled(false);
      })
      .catch(error => {
        console.error('error: ', error);
        setBtnDisabled(false);
      });
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };


  useEffect(() => {
    if(!decodedToken){
      return;
    }
    axios({
      method: 'get',
      url: `${API_BASE_URL}/chapters`,
      headers: {Authorization: `Bearer ${decodedToken}`}})
      .then(result => setChapterList(result.data))
      .catch(error => console.error('error: ', error));
  }, [decodedToken]);

  const questionCard = (question, index) => {
    return (
      <Grid item xs={12} sm={12} key={index}>
        <Grid container>
          <Grid item xs={1} sm={1}>
            <Typography className={classes.quizIndex}>
              {index + 1}.
            </Typography>
          </Grid>
          <Grid item xs={10} sm={10}>
            <Typography className={classes.quizQuestion} dangerouslySetInnerHTML={{__html: escapeHTMLTags(question.question)}}>
              {/*setInner{question.question}*/}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1}>
            <IconButton name="test" onClick={() => handleEdit(question['question_id'])}>
              <EditIcon className={classes.editIcon}/>
            </IconButton>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </Grid>
    );
  }

  if( !ALLOWED_EMAILS.includes(userEmail)){
    return <h1>Access respins</h1>
  }

  if (!chapterList.length) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Grid container spacing={2}>
        <Grid item xs={8} sm={8}>
          <TextField
            className={clsx(classes.textField)}
            fullWidth
            label="Întrebare"
            name="question"
            onChange={handleChange}
            type="text"
            value={formState.values.question}
            variant="outlined"
          />
          </Grid>
          <Grid item xs={4} sm={4}>
            <div className={classes.saveSection}>
              <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={handleSearch}
                disabled={isBtnDisabled}
              >
                CAUTĂ
              </Button>
            </div>
          </Grid>
          {questions.map(questionCard)}
        </Grid>
      </Card>
    </div>
  );
};

export default compose(withFirebase, withAuthorization)(Questions);
