export const validationSchema = {
  email: {
    presence: {
      allowEmpty: false,
      message: "Vă rugăm introduceți adresa email",
    },
    email: {
      message: "Adresa email nu este validă",
    },
    length: {
      maximum: 64,
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
      message: "Va rugăm introduceți prenumele",
    },
    format: {
      pattern: "[a-z]+",
      flags: "i",
      message: "Nu trebuie să conțină caractere speciale sau cifre (\",.>' '1)",
    },
    length: {
      maximum: 16,
      tooLong: "Prenumele este prea lung (maxim 16 caractere)",
      minimum: 3,
      tooShort: "Prenumele este prea scurt (minim 3 caractere)",
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: "Va rugăm introduceți numele",
    },
    format: {
      pattern: "[a-z]+",
      flags: "i",
      message: "Nu trebuie să contină caractere speciale sau cifre (\",.>' '1)",
    },
    length: {
      maximum: 16,
      tooLong: "Numele este prea lung (maxim 16 caractere)",
      minimum: 3,
      tooShort: "Numele este prea scurt (minim 3 caractere)",
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: "Vă rugăm introduceți parola",
    },
    length: {
      maximum: 16,
      tooLong: "Parola este prea lungă (maxim 16 caractere)",
      minimum: 6,
      tooShort: "Parola trebuie să conțină minim 6 caractere",
    },
  },
  confirmPassword: {
    presence: {
      allowEmpty: false,
      message: "Vă rugăm introduceți parola",
    },
    equality: {
      attribute: "password",
      message: "Parolele introduse nu sunt egale",
    },
    length: {
      maximum: 16,
      tooLong: "Parola este prea lungă (maxim 16 caractere)",
      minimum: 6,
      tooShort: "Parola trebuie să conțină minim 6 caractere",
    },
  },
};
