import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    position: "absolute",
    padding: 0,
    height: "48px",
    width: "128px",
    backgroundColor: "#2B3058",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    borderRadius: "30px",
    "&:hover":{
      backgroundColor: "#2B3058"
    }
  },
  icon: {
    height: "15px",
    width: "15px",
    color: "#FFFFFF",
  },
  left: {
    top: "234px",
    left: "-16%",
  },
  right: {
    top: "234px",
    right: "-16%",
  },
}));

const ArrowButton = ({ position, text, onClick, customStyles }) => {
  const classes = useStyles();

  const defaultStyles = clsx(classes.button, {
    [classes.left]: position === "left",
    [classes.right]: position === "right",
  });

  return (
    <Button
      onClick={onClick}
      startIcon={
        position === "left" ? (
          <ArrowBackIosIcon className={classes.icon} />
        ) : null
      }
      endIcon={
        position === "right" ? (
          <ArrowForwardIosIcon className={classes.icon} />
        ) : null
      }
      className={customStyles ? customStyles : defaultStyles}
      variant="contained"
    >
      {text}
    </Button>
  );
};

export default ArrowButton;
