import React from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import axios from 'axios';
import AuthUserContext from './context';

const withAuthorization = Component => {
  class WithAuthorization extends React.Component {
    constructor(props){
      super(props);

      this.state = {
        role: null,
        decodedToken: null,
        createdAt: null,
      }

      this.makeRequest = this.makeRequest.bind(this)
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser === null) {
          this.props.history.push('/sign-in');
          return;
        }
       
        this.props.firebase.auth.currentUser.getIdTokenResult().then(idTokenResult => {
          // console.log('idTokenResult: ',idTokenResult);
          // console.log('TOKEN: ', idTokenResult);
          const claims = idTokenResult?.claims.role;
          const decodedToken = idTokenResult.token;
          const createdAt = new Date(idTokenResult.issuedAtTime);
          this.setState(prevState => ({
            ...prevState, claims, decodedToken, createdAt: createdAt.getTime()
          }));
        });
      });
    }

    async makeRequest (axiosOptions) {
      const {decodedToken, createdAt} = this.state;
      let latestToken = decodedToken;
      if(!decodedToken || Date.now() - createdAt > 1000 * 60 * 10) {
        const newToken = await this.props.firebase.auth.currentUser?.getIdTokenResult(true);
        const createdAt = new Date(newToken.issuedAtTime);
        this.setState(prevState => ({
          ...prevState, decodedToken: newToken.token, createdAt: createdAt.getTime()
        }));
        latestToken = newToken.token;
      }

      return axios({
        ...axiosOptions,
        headers:{
          ...axiosOptions.headers,
          Authorization: `Bearer ${latestToken}`
        },
      })
    }


    componentWillUnmount() {
      this.listener();
    }

    render() {
      // console.log('STATE: ',this.state);
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            authUser === null ? null : <Component {...this.props} decodedToken={this.state.decodedToken} makeRequest={this.makeRequest} />
          }
        </AuthUserContext.Consumer>
      );
    }
  }
  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
