import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider
} from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import {EmptyState} from "../../components";
import {API_BASE_URL} from "../../common/constants";
import Loading from "../../components/Loading";
import { ArrowButton } from "../Dashboard/components";

require('react-quill/dist/quill.snow.css');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems:"center",
    width: "976px",
    "@media only screen and (max-width: 1200px)":{
      width:"100%",
    }
  },
  elementWrapper: {
    display:"flex",
    flexDirection:"column",
    padding: "34px 40px 0px 40px"
  },
  timerIcon:{
    width: "291px",
    height: "291px",
    marginTop: "150px",
    marginBottom: "24px",
    color:"rgb(27, 48, 88)"
  },
  title:{
    fontFamily: "Roboto",
    color: "rgb(27, 48, 88)",
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "56px",
    marginBottom: "16px",
  },
  description:{
    fontFamily: "Roboto",
    color: "rgb(27, 48, 88)",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    marginBottom: "32px",

  },
  saveButton: {
    height: "49px",
    width: "120px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  buttonContainer:{
    display: "flex",
    justifyContent:"flex-start",
    width:"100%",
    marginTop: "50px"
  },
  customArrowButtons:{
    marginLeft: "16px",
    padding: 0,
    height: "48px",
    width: "128px",
    backgroundColor: "#2B3058",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    borderRadius: "30px",
    "&:hover":{
      backgroundColor: "#2B3058"
    }
  },
  divider:{
    textAlign:"center",
    height: "2px",
    backgroundColor: "rgb(27, 48, 88)",
    margin:"auto",
    minWidth: "480px",
    "@media only screen and (max-width: 480px)":{
      minWidth:"unset",
      width:"100%"
    }
  },
}));

const FAQ = ({ firebase, decodedToken }) => {
  const classes = useStyles();
  const history = useHistory();

  const [faq, setFaq] = useState([
    {
      title: "News testing 1",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      title: "News testing 2",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      title: "News testing 3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }
  ]);



  const userEmail = firebase.auth.currentUser.email;


  // useEffect(() => {
  //   if(!decodedToken){
  //     return;
  //   }
  //   axios({
  //     method: 'get',
  //     url: `${API_BASE_URL}/chapters`,
  //     headers: {Authorization: `Bearer ${decodedToken}`}})
  //     .then(result => setFaq(result.data))
  //     .catch(error => console.error('error: ', error));
  // }, [decodedToken]);


  // if (!news) {
  //   return <Loading />;
  // }

  if(true) {
    return <EmptyState
      title="Nu am publicat încă întrebări."
      subtitle="Nu va dura mult pana vor aparea primele întrebări,
revin-o la pagină peste puțin timp."
    />
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <ArrowButton position="left"  customStyles={classes.customArrowButtons} text="Back" onClick={() => history.push("/")} />
        </div>
        {
          faq.map((element,index) => (
            <div key={index} className={classes.elementWrapper}>
              <Typography className={classes.title}>
                {element.title}
              </Typography>
              <Typography className={classes.description}>
                {element.description}
              </Typography>
              <Divider className={classes.divider} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default compose(withFirebase, withAuthorization)(FAQ);
