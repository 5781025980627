/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { withFirebase } from "../../../../../../Firebase";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: "rgb(43, 48, 88)",
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: "rgb(43, 48, 88)",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: "rgb(43, 48, 88)",
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: "rgb(255, 203, 0)",
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const doSignOut = () => {
    props.firebase.doSignOut();
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
          >
            <div className={classes.icon}>{page.icon}</div>
            <span>{page.title}</span>
          </Button>
        </ListItem>
      ))}
      <ListItem className={classes.item} disableGutters key="LogOut">
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={CustomRouterLink}
          onClick={doSignOut}
          to={"/sign-in"}
        >
          <div className={classes.icon}>
            <ExitToAppIcon />
          </div>
          {"Ieșire"}
        </Button>
      </ListItem>
    </List>
  );
};


export default withFirebase(SidebarNav);
