import React from "react";
import clsx from "clsx";
import { Typography, TextField, Button, Divider } from "@material-ui/core";

const EmailStage = ({
  classes,
  hasError,
  formState,
  handleChange,
  handleCheckEmail,
}) => {
  return (
    <>
      <Typography
        className={clsx(classes.loginText, classes.loginTextMarginBottom)}
        align="center"
      >
        Accesează cont
      </Typography>
      <TextField
        className={classes.textField}
        error={hasError("email")}
        fullWidth
        helperText={hasError("email") ? formState.errors.email[0] : null}
        label="Email"
        name="email"
        onChange={handleChange}
        type="text"
        value={formState.values.email || ""}
        variant="outlined"
      />
      <Button
        className={classes.signInButton}
        color="primary"
        // disabled={!formState.isValid}
        fullWidth
        onClick={handleCheckEmail}
        variant="contained"
      >
        Continuă
      </Button>
      <Divider className={classes.divider} />
      <Typography className={classes.helperText} align="center">
        Dacă nu ai cont, poți crea unul în pasul următor.
      </Typography>
    </>
  );
};

export default EmailStage;
