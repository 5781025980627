import React from "react";
import { withAuthentication } from "./Session";
import { withFirebase } from "./Firebase";
import { SnackbarProvider } from "notistack";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "react-perfect-scrollbar/dist/css/styles.css";

import Routes from "./Routes";
const browserHistory = createBrowserHistory();

const App = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </SnackbarProvider>
  );
};

export default withAuthentication(withFirebase(App));
