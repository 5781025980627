import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { SubscriptionCard } from "../../../components";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import {
  FREE_TRIAL_PRICE,
  PREMIUM_SUBSCRIPTION_PRICE,
} from "../../../common/constants";

const useStyle = makeStyles((theme) => ({
  loginText: {
    color: "#6B6B6B",
    fontFamily: "Roboto",
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "36px",
    textAlign: "center",
  },
  registerTextMarginBottom: {
    marginBottom: "8px",
  },
  emailText: {
    color: "#AEAEAE",
    fontSize: "16px",
    lineHeight: "21px",
    marginBottom: "68px",
  },
  signInButton: {
    height: "49px",
    lineHeight: "18px",
    marginBottom: "20px",
    marginTop: "72px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  checkIcon: {
    marginRight: "5px",
  },
  loadingWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "288px",
  },
  loading: {
    color: "#00376A",
    zIndex: 2,
  },
}));

const SubscriptionStage = ({
  selectedOffer,
  setSelectedOffer,
  handleBuySubscription,
  loading,
}) => {
  const classes = useStyle();
  const isBtnDisabled = selectedOffer === null || loading;
  const handleSelectOffer = (price) => setSelectedOffer(price);

  return (
    <>
      <Typography
        className={clsx(classes.loginText, classes.registerTextMarginBottom)}
        align="center"
      >
        Subscripție
      </Typography>
      <Typography className={classes.emailText} align="center">
        Selectează subscripția dorită
      </Typography>
      {loading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress className={classes.loading} />
        </div>
      ) : (
        <>
          <SubscriptionCard
            title="Subscripția de bază"
            description="Valabilă 30 de zile"
            price={PREMIUM_SUBSCRIPTION_PRICE}
            onClick={handleSelectOffer}
            selected={selectedOffer === PREMIUM_SUBSCRIPTION_PRICE}
            // disabled={true}
          />
          <SubscriptionCard
            title="Subscripție gratuită"
            description="Valabilă 24 de ore"
            price={FREE_TRIAL_PRICE}
            onClick={handleSelectOffer}
            selected={selectedOffer === FREE_TRIAL_PRICE}
          />
        </>
      )}

      <Button
        className={classes.signInButton}
        color="primary"
        variant="contained"
        disabled={isBtnDisabled}
        fullWidth
        onClick={handleBuySubscription}
      >
        <CheckCircleIcon className={classes.checkIcon} /> Înregistrează-te
      </Button>
    </>
  );
};

export default SubscriptionStage;
