import { QUIZ_OPTIONS } from "./constants";

export const escapeOtherHTMLTags = (strInputCode) => {
  const regexAll = /(<span[^>]+?>|<span>|<\/span>|<em[^>]+?>|<em>|<\/em>)/img;
  const cleanText = strInputCode.replace(regexAll, "");
  return cleanText;
}

export const escapeHTMLPTags = (strInputCode) => {
  const removeAllPTags = /(<p[^>]+?>|<p>|<\/p>)/img;
  const cleanText = strInputCode.replace(removeAllPTags, "");
  return cleanText;
}

export const calculateResultForQuestion = question => {
  let currentPoints = [];
  const isSingleAnswerQuestion = question.correctAnswers.length === 1;
  const maximumPointsForSelectedAnswer = 1 / question.correctAnswers.length;

  const calculatePointsForSelectedOption = (pointsArray, option) => {
    //selected wrong answer always result in failed quiz
    const difference = question.selectedAnswers.filter(selection => !question.correctAnswers.includes(selection));

    if(difference.length){
      return currentPoints.push(0);
    }

    if (isSingleAnswerQuestion) {
      if (
        question.selectedAnswers.includes(option) &&
        question.correctAnswers.includes(option) &&
        question.selectedAnswers.length === 1
      ) {
        currentPoints.push(1);
      } else {
        currentPoints.push(0);
      }
    } else {
      if (question.selectedAnswers.length > 4
      ) {
        return currentPoints.push(0);
      }
      if (
        question.selectedAnswers.includes(option) &&
        question.correctAnswers.includes(option)
      ) {
        return pointsArray.push(maximumPointsForSelectedAnswer);
      } else if (
        question.selectedAnswers.includes(option) &&
        !question.correctAnswers.includes(option)
      ) {
        return pointsArray.push(0);
      } else if (
        !question.selectedAnswers.includes(option) &&
        !question.correctAnswers.includes(option)
      ) {
        return pointsArray.push(0);
      } else if (
        !question.selectedAnswers.includes(option) &&
        question.correctAnswers.includes(option)
      ) {
        return pointsArray.push(0);
      }
    }
  };

  QUIZ_OPTIONS.forEach(option => {
    calculatePointsForSelectedOption(currentPoints, option);
  });

  let accumulatedPoints = currentPoints.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const isPassed = accumulatedPoints > 0;
  const text = !isPassed ? "GREȘIT": accumulatedPoints === 1 ? "CORECT" : "PARȚIAL CORECT";
  // console.log('currentSolvedQuestions: ')

  return {
    isPassed,
    text
  };
}