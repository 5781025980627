import React from "react";
import {
  makeStyles, Typography, Divider,
} from "@material-ui/core";

import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import { ArrowButton } from "../../views/Dashboard/components";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems:"center",
    width: "976px",
    "@media only screen and (max-width: 1200px)":{
      width:"100%",
    }
  },
  timerIcon:{
    width: "291px",
    height: "291px",
    marginTop: "100px",
    marginBottom: "24px",
    color:"rgb(27, 48, 88)",
  },
  title:{
    fontFamily: "Roboto",
    color: "rgb(27, 48, 88)",
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "56px",
    marginBottom: "16px",
  },
  subTitle:{
    fontFamily: "Roboto",
    color: "rgb(27, 48, 88)",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    marginBottom: "18px",
  },
  divider:{
    height: "2px",
    backgroundColor: "rgb(27, 48, 88)",
    margin:"0 30px",
    minWidth: "480px",
    "@media only screen and (max-width: 480px)":{
      minWidth:"unset",
      width:"100%"
    }
  },
  buttonContainer:{
    display: "flex",
    justifyContent:"flex-start",
    width:"100%",
    marginTop: "50px"
  },
  customArrowButtons:{
    marginLeft: "16px",
    padding: 0,
    height: "48px",
    width: "128px",
    backgroundColor: "#2B3058",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    borderRadius: "30px",
    "&:hover":{
      backgroundColor: "#2B3058"
    }
  },
}));

const EmptyState = ({title, subtitle}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <ArrowButton position="left"  customStyles={classes.customArrowButtons} text="Back" onClick={() => history.push("/")} />
      </div>
      <TimerOutlinedIcon className={classes.timerIcon} />
      <Typography className={classes.title} align="center">
        {title}
      </Typography>
      <Typography className={classes.subTitle} align="center">
        {subtitle}
      </Typography>
      <Divider className={classes.divider}/>
    </div>
    </div>
  );
};

export default EmptyState;
