import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Typography,
} from "@material-ui/core";
import Grow from "@material-ui/core/Zoom";
import clsx from "clsx";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { useChapterValue } from "../FilterGroup";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    height: "242px",
    minWidth: "220px",
    margin: "16px",
    cursor: "pointer",
    backgroundColor: "#2B3058"
  },
  title: {
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500px",
    lineHeight: "24px",
    width: "188px",
    textOverflow: "ellipsis",
  },
  cardActionArea: {
    height: "173px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "150px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "68px",
    padding: "13px 16px 10px 16px",
    borderTop: "5px solid #FFCB00"
  },
  checkIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    height: "24px",
    wdith: "24px",
    color: "#1B3058",
  },
  selectIcon: {
    color: "#1B3058",
  },
  subtitle: {
    color: "#FFFFFF",
    marginLeft: "0px !important",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
}));

const ChapterCard = ({
  id,
  title,
  subtitle,
  cardBackgroundColor,
  cardBackgroundUrl,
  isSelectMode,
  order,
}) => {
  const [raised, setRaised] = useState(false);
  const [selectedChapter, selectChapter, removeChapter] = useChapterValue(
    id,
    undefined
  );

  const toggleHoverRaised = () => setRaised(!raised);

  const displaySelected = selectedChapter && isSelectMode;
  const displayUnchecked = !selectedChapter && isSelectMode;

  const classes = useStyles();
  return (
    <Grow in={true} timeout={(order + 1) * 150}>
      <Card
        className={classes.card}
        raised={raised}
        onMouseEnter={toggleHoverRaised}
        onMouseLeave={toggleHoverRaised}
        onClick={() =>
          selectedChapter ? removeChapter(id) : selectChapter([])
        }
      >
        <CardActionArea
          className={classes.cardActionArea}
          style={{
            backgroundImage: `url(${cardBackgroundUrl})`,
            backgroundColor: `${cardBackgroundColor}`,
          }}
        />
        <div
          className={classes.cardActions}
          style={{
            backgroundColor: displaySelected
              ? "rgba(27, 48, 88, 0.4)"
              : "unset",
          }}
        >
          <Typography noWrap title={title} className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.subtitle}>
            {subtitle} {subtitle > 1 ? "subcapitole" : "subcapitol"}
          </Typography>
        </div>
        {displaySelected && (
          <div className={classes.checkIcon}>
            <CheckCircleIcon />
          </div>
        )}
        {displayUnchecked && (
          <div className={clsx(classes.checkIcon, classes.selectIcon)}>
            <RadioButtonUncheckedIcon />
          </div>
        )}
      </Card>
    </Grow>
  );
};

export default ChapterCard;
