import React, { useMemo } from "react";
import { makeStyles, Card, Typography, Button } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";

const useStyles = makeStyles(() => ({
  userHistoryCard: {
    display: "flex",
    flexDirection:"column",
    width: "788px",
    marginTop: "24px",
    padding: "50px 0",
    "@media only screen and (max-width: 804px)":{
      width:"100%",

    }
  },
  heading: {
    display:"flex",
    justifyContent:"center",
    color: "#6B6B6B",
    fontFamily: "Roboto",
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "36px",
    marginBottom: "8px",
  },
  subHeading: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
  },
  chapterTitle:{
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "20px",
    marginTop: "8px",
    marginBottom: "8px"
  },
  quizPercentage:{
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: "28px",
    fontWeight: 500,
    letterSpacing: 0.21,
    lineHeight: "32px",
    marginTop: "20px",
    marginBottom: "8px"
  },
  tableRow:{
    "&:not(:last-child)":{
      borderBottom: "1px solid rgb(72, 77, 113)",
    },
    borderBottom: "1px solid rgb(72, 77, 113)",
  },
  historyList:{
    padding: "32px 24px",
  },
  historyRow:{
    marginBottom: "34px"
  },
  tableCell:{
    maxWidth: "50%"
  },
  quizPercentageCell:{
    textAlign:"right"
  },
  divider:{
    height: "2px",
    color: "rgb(72, 77, 113)"
  },
  continueBtn: {
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  loadingWrapper:{
    display:"flex",
    width:"100%",
    alignItems:"center"
  },
  loading: {
    color: "#FFCB00",
    zIndex: 2,
  },
}));


const UserHistory = ({userQuizzes, fetchMoreQuizzes, count}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleContinueQuiz = (quizId) => {
    history.push(`/quiz/${quizId}`);
  }

  const data = useMemo(
    () => [...userQuizzes],
    [userQuizzes]
  );

  const columns = useMemo(
    () => [
      {
        id: 'quizDate',
        Header: '',
        Cell: args => {
          const {
            row: {
              original: {
                createdAt,
                subjectName,
                chapterTitle,
                questionsNo
              },
            },
          } = args;

          const quizDate = new Date(createdAt);
          const subjects = subjectName.join(",");
          const chapters = chapterTitle.join(",");

          return (
            <div>
              <Typography className={classes.subHeading}>{`${moment(quizDate).format('DD.MM.YYYY')}`}</Typography>
              <Typography title={`${subjects} - ${chapters}`} noWrap className={classes.chapterTitle}>{`${subjects} - ${chapters}`}</Typography>
              <Typography className={classes.subHeading}>{`${questionsNo} întrebări`}</Typography>
            </div>
          );
        }
      },
      {
        id:'quizPercentage',
        Header: '',
        Cell: args => {
          const {
            row: {
              original: {
                isResolved,
                correctAnswers,
                solvedPercentage,
                questionsNo,
                quizId,
              },
            },
          } = args;

          return (
            <div className={classes.quizPercentageCell}>
              {
                isResolved ? (
                  <Typography className={classes.quizPercentage}>{solvedPercentage}%</Typography>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.continueBtn}
                    onClick={() => handleContinueQuiz(quizId)}
                    // disabled={isBtnDisabled}
                  >
                    CONTINUĂ
                  </Button>
                )
              }
              <Typography className={classes.subHeading}>{`${correctAnswers} corecte din ${questionsNo}`}</Typography>
            </div>
          )
        }
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <Card className={classes.userHistoryCard}>
      <Typography className={classes.heading}>Istoricul grilelor</Typography>
      <div className={classes.historyList}>
        {
          !userQuizzes.length ? (<Typography align="center" className={classes.subHeading}>Nu exista istoric</Typography>) : (<InfiniteScroll
              dataLength={rows.length}
              next={fetchMoreQuizzes}
              hasMore={!(Number(count) === userQuizzes.length)}
              loader={<span>Se încarcă...</span>}
            >
              <table {...getTableProps()}
                     style={{
                       tableLayout:'fixed',
                       width:"100%",
                       borderCollapse: "separate",
                       borderSpacing: "0 16px",
                     }}
              >
                <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()} >
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} className={classes.tableRow}>
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </InfiniteScroll>
          )
        }
      </div>
    </Card>
  )
}

export default UserHistory;