import React from "react";
import { Typography, Grid, useMediaQuery, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  footer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#1B3058",
    justifyContent: "space-between",
    width: "100%",
    borderTop: "solid 8px #FFCB00"
  },
  grid: {
    padding: "18px 30px",
  },
  companyDetailsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
  },
  termsAndConditionsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "@media only screen and (max-width: 1325px)": {
      flexDirection: "column",
    },
  },

  poweredByWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    height: "100%",
  },
  body1: {
    fontSize: " 10px",
    lineHeight: "14px",
    letterSpacing: "0.33px",
    color: "#FFFFFF",
  },
  body2: {
    color: "#FFFFFF",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0.18px",
    marginRight: "50px",
    marginBottom: "10px",
    "@media only screen and (max-width: 650px)": {
      fontSize: "18px",
      marginRight: "0px",
    },
  },
  link: {
    color: "#FFFFFF",
    textDecorationColor: "#FFFFFF",
  },
  body3: {
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.11px",
    lineHeight: "24px",
  },
  smallDevices: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "24px 46px",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const matches600 = useMediaQuery("(max-width:650px)");

  return (
    <div {...rest} className={clsx(classes.footer, className)}>
      {matches600 ? (

        <div className={classes.smallDevices}>
          <Link to="/contact" className={classes.link}>
          <Typography className={classes.body1} align="center">
          Copyright © 2022 Universitatea
          </Typography>
          <Typography className={classes.body1} align="center">
          de Medicină și Farmacie Victor
          </Typography>
          <Typography className={classes.body1} align="center">
          Victor Babeș | TIMIȘOARA
          </Typography>
          {/*<Typography className={classes.body1} align="center">*/}
          {/*Suport site:*/}
          {/*</Typography>*/}
          </Link>
        </div>

      ) : (
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={2}>
            <Link to="/contact" className={classes.link}>
            <div className={classes.companyDetailsWrapper}>
              <Typography className={classes.body1}>
              Copyright © 2022 Universitatea
              </Typography>
              <Typography className={classes.body1}>de Medicină și Farmacie Victor</Typography>
              <Typography className={classes.body1}>
              Victor Babeș | TIMIȘOARA
              </Typography>

              {/*<Typography className={classes.body1}>*/}
              {/*Suport site:*/}
              {/*</Typography>*/}
            </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className={classes.termsAndConditionsWrapper}>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Footer;
