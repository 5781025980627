import React, { useState, useEffect } from "react";
import { makeStyles, Card, Grid, Grow } from "@material-ui/core";
import { useSnackbar } from "notistack";
import validate from "validate.js";

import DetailsSection from "./DetailsSection";

const useStyles = makeStyles(() => ({
  userDetailsCard: {
    display: "flex",
    maxWidth: "788px",
    marginTop: "24px",
    padding: "50px 0",
  },
  subscription: {
    display: "flex",
    flexDirection: "column",
    height: "476px",
    alignItems: "center",
    width: "100%",
    borderRight: "1px solid #00376A",
  },
  accountForm:{
    padding: "32px 24px"

  },
  account: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    color: "#6B6B6B",
    fontFamily: "Roboto",
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "36px",
    marginBottom: "8px",
  },
  subHeading: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    marginBottom: "24px",
  },
  textField: {
    borderRadius: "4px",
    marginBottom:"16px",
    height: "53.63px"
  },
  cancelBtn: {
    marginTop: "26px",
    height: "49px",
    width: "328px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  updateBtn: {
    marginTop: "32px",
    height: "49px",
    width: "328px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  loadingWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "288px",
  },
  loading: {
    color: "#00376A",
    zIndex: 2,
  },
}));

const validationSchema = {
  firstName: {
    presence: { allowEmpty: false, message: "Va rugăm introduceți prenumele" },
    format: {
      pattern: "[a-z]+",
      flags: "i",
      message: "Nu trebuie să conțină caractere speciale sau cifre (\",.>' '1)",
    },
    length: {
      maximum: 16,
      tooLong: "Prenumele este prea lung (maxim 16 caractere)",
      minimum: 3,
      tooShort: "Prenumele este prea scurt (minim 3 caractere)",
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "Va rugăm introduceți numele" },
    format: {
      pattern: "[a-z]+",
      flags: "i",
      message: "Nu trebuie să contină caractere speciale sau cifre (\",.>' '1)",
    },
    length: {
      maximum: 16,
      tooLong: "Numele este prea lung (maxim 16 caractere)",
      minimum: 3,
      tooShort: "Numele este prea scurt (minim 3 caractere)",
    },
  },
  currentPassword: {
    length: {
      maximum: 16,
      tooLong: "Parola este prea lungă (maxim 16 caractere)",
      minimum: 0,
      tooShort: "Parola trebuie să conțină minim 6 caractere",
    },
  },
  newPassword: {
    // equality: {
    //   attribute: "newPassword",
    //   message: "Nu este egala cu parola noua",
    // },
    presence: false,
    length: {
      maximum: 16,
      tooLong: "Parola este prea lunga (maxim 16 caractere)",
      minimum: 0,
      tooShort: "Parola trebuie să contină minim 6 caractere",
    },
  },
};

const INITIAL_STATE = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
};

validate.options = {
  fullMessages: false,
};

const UserDetails = ({ userDetails, firebase }) => {
  const classes = useStyles();
  const user = firebase.auth.currentUser;
  const { displayName } = user;

  const parsedDisplayName = JSON.parse(displayName);
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formState, setFormState] = useState({
    ...INITIAL_STATE,
    values: {
      email: userDetails.email,
      firstName: parsedDisplayName.firstName,
      lastName: parsedDisplayName.lastName,
      currentPassword: "",
      newPassword: "",
    },
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    }));
  };

  const handleUpdate = () => {
    if (formState.touched.firstName || formState.touched.lastName) {
      setBtnDisabled(true);
      firebase
        .updateProfile(formState.values.firstName, formState.values.lastName)
        .then(() => {
          enqueueSnackbar("Schimbările au fost salvate cu succes.", {
            variant: "success",
          });
          setBtnDisabled(false);
        })
        .catch(() => {
          enqueueSnackbar("Schimbările nu au fost salvate.", {
            variant: "error",
          });
          setBtnDisabled(false);
        });
    }

    if (formState.touched.newPassword || formState.touched.confirmPassword) {
      const credential = firebase.createCredential(
        userDetails.email,
        formState.values.currentPassword
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => user.updatePassword(formState.values.newPassword))
        .then(
          () => {
            enqueueSnackbar("Parola a fost schimbată cu succes.", {
              variant: "success",
            });
            setBtnDisabled(false);
          },
          (error) => {
            enqueueSnackbar("Parola introdusă nu este corectă.", {
              variant: "error",
            });
            setBtnDisabled(false);
          }
        )
        .catch((error) => console.error("Error: ", error));

      setBtnDisabled(true);
    }
  };

  useEffect(() => {
    if (
      formState.values.currentPassword.length ||
      formState.values.newPassword.length
    ) {
      validationSchema.currentPassword.length.minimum = 6;
      validationSchema.newPassword.length.minimum = 6;
    } else {
      validationSchema.currentPassword.length.minimum = 0;
      validationSchema.newPassword.length.minimum = 0;
    }

    const errors = validate(formState.values, validationSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    setBtnDisabled(errors ? true : false);
  }, [formState.values]);

  return (
    <Grow in={true} timeout={600}>
      <Card className={classes.userDetailsCard}>
        <Grid
          container
          direction="row"
        >
          <Grid item xs={12} sm={12}>
            <DetailsSection
              classes={classes}
              handleChange={handleChange}
              handleClickShowPassword={handleClickShowPassword}
              handleClickShowConfirmPassword={handleClickShowConfirmPassword}
              showConfirmPassword={showConfirmPassword}
              showPassword={showPassword}
              formState={formState}
              handleUpdate={handleUpdate}
              isBtnDisabled={isBtnDisabled}
            />
          </Grid>
        </Grid>
      </Card>
    </Grow>
  );
};

export default UserDetails;
