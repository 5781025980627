import React from "react";
import { makeStyles, Typography, Card, Button } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  card: {
    minHeight: "100px",
    padding: "16px",
    backgroundColor: "#2B3058",
    margin: "16px",
    width: "248px",
  },
  positionTopCornerRight: {
    position: "absolute",
    top: "80px",
    right: "16px",
  },
  message: {
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginBottom: "18px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    borderRadius: "4px",
    backgroundColor: "#FFCB00",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    width: "100%",
    height: "49px",
    border: "unset",
    color: "#1B3058",
    "&:hover": {
      backgroundColor: "rgba(255, 153, 98, 1)",
    },
  },
  secondaryButton: {
    width: "unset",
  },
}));

const NotificationBox = ({
  description,
  buttonText,
  visibility,
  absolute,
  onClick,
}) => {
  const classes = useStyles();
  if (!visibility) {
    return null;
  }

  return (
    <Card
      className={clsx(classes.card, {
        [classes.positionTopCornerRight]: absolute,
      })}
    >
      <Typography className={classes.message}>{description}</Typography>
      <div className={classes.buttonWrapper}>
        <Button
          className={clsx(classes.button, {
            [classes.secondaryButton]: !absolute,
          })}
          variant="outlined"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    </Card>
  );
};

export default NotificationBox;
