import React from "react";
import {
  Typography,
  TextField,
  Button,
  Divider,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

import doctorImg from "../doctor.png";

const PasswordStage = ({
  error,
  classes,
  hasError,
  formState,
  handleChange,
  showPassword,
  handleClickShowPassword,
  handleSignIn,
  handleResetPassword,
}) => {
  return (
    <>
      <img className={classes.doctorImg} src={doctorImg} alt="doctor" />
      <Typography className={classes.emailText} align="center">
        {formState.values.email}
      </Typography>
      <TextField
        className={classes.textField}
        error={hasError("password") || !!error}
        fullWidth
        helperText={
          hasError("password")
            ? formState.errors.password[0]
            : !!error
            ? "Parola introdusă este gresită"
            : null
        }
        name="password"
        label="Parolă"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={!showPassword ? "password" : "text"}
        value={formState.values.password || ""}
        variant="outlined"
      />
      <Button
        className={classes.signInButton}
        color="primary"
        // disabled={!formState.isValid}
        fullWidth
        onClick={handleSignIn}
        variant="contained"
      >
        Conectează-te
      </Button>
      <Divider className={classes.divider} />
      <Typography
        className={classes.resetPasswordText}
        align="left"
        onClick={handleResetPassword}
      >
        Resetează parola
      </Typography>
    </>
  );
};

export default PasswordStage;
