import React from "react";
import { makeStyles, Card, Typography, Button } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Grow from "@material-ui/core/Zoom";

import clsx from "clsx";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "432px",
    width: "768px",
    marginBottom: "100px",
    "@media only screen and (max-width: 804px)":{
      width:"100%",
    }
  },
  icon: {
    height: "93px",
    width: "84px",
    color: "rgb(86, 163, 166)",
  },
  resultWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
    padding: "24px",
  },
  congratulations: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "24px",
    marginTop: "25px",
  },
  results: {
    fontSize: "20px",
  },

  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: "24px",
  },

  answerButton: {
    height: "49px",
    width: "188px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
}));

const Congratulations = ({
  visibility,
  points,
  totalPoints,
  solvedPercentage,
  onClick,
}) => {
  const classes = useStyles();
  if (!visibility) {
    return null;
  }

  return (
    <Grow in={visibility} timeout={600}>
      <Card className={classes.card}>
        <div className={classes.resultWrapper}>
          <AssignmentTurnedInIcon className={classes.icon} />
          <Typography className={classes.congratulations}>
            Felicitări!
          </Typography>
          <Typography className={classes.congratulations}>
            {solvedPercentage + "%"}
          </Typography>
          <Typography
            className={clsx(classes.congratulations, classes.results)}
          >
            Ai obținut un punctaj de {(Math.round(points * 100) / 100).toFixed(2)} / {totalPoints} pct.
          </Typography>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            variant="contained"
            className={classes.answerButton}
            onClick={onClick}
          >
            crează altă grilă
          </Button>
        </div>
      </Card>
    </Grow>
  );
};

export default Congratulations;
