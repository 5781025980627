import React from "react";
import { makeStyles } from "@material-ui/core";
import { Footer } from "../Main/components";

const useStyles = makeStyles(() => ({
  minimalWrapper: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  content: {
    flex: 1,
    display: "flex",
  },
}));

const Minimal = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.minimalWrapper}>
      <main className={classes.content}>{children}</main>
      <Footer />
    </div>
  );
};
export default Minimal;
