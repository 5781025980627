import React, { useState, useEffect } from "react";
import ReactQuill from 'react-quill';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Switch, FormControlLabel, Checkbox, Dialog
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from "axios";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { useSnackbar } from "notistack";
import { ALLOWED_EMAILS, API_BASE_URL } from "../../common/constants";
import clsx from "clsx";
import Loading from "../../components/Loading";

require('react-quill/dist/quill.snow.css');

const INITIAL_STATE = {
  isValid: false,
  values: {
    A: "",
    B: "",
    C: "",
    D: "",
    E: "",
    questionId: "",
    question: "",
    explanation: "",
  },
  touched: {},
  errors: {},
  checked: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
  },
};

const escapeHTMLTags = (strInputCode) => {
  // var pattern = @"<(img|a)[^>]*>(?<content>[^<]*)<";
  const pattern = new RegExp('@"<(p|strong|span)[^>]*>(?<content>[^<]*)<"');
  // const pattern = new RegExp('/<\\/?[\\w\\s]*>|<.+[\\W]>/g');
  // const cleanText = strInputCode.replace(/<\/?[^>]+(>|$)/g, "");
  const cleanText = strInputCode.replace(/(<strong>|<\/strong>)/g, "");
  return cleanText;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    marginTop: "50px",
    width: "768px",
    height: "auto",
    padding: "34px 58px 34px 58px",
  },
  quillText:{
    marginBottom: '30px',
    '& .MuiInputLabel-formControl': {
      top: '-40px !important'
    }
  },
  textField: {
    minHeight: "5rem",
    marginBottom: "5px",
    borderRadius: "4px",
  },
  switchContainer: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  switchText: {
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
    width: "80px",
  },
  switchTextDisabled: {
    opacity: "0.4",
  },
  formControl: {
    minHeight: "5rem",
  },
  saveButton: {
    height: "49px",
    width: "120px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  cancelButton: {
    height: "49px",
    marginRight: '16px',
    width: "120px",
    borderRadius: "4px",
    backgroundColor: "#FFCB00",
    '&:hover':{
      backgroundColor: "#FFCB00",
    }
  },
  saveSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  explanationButton: {
    height: "49px",
    borderRadius: "4px",
    backgroundColor: "#FFCB00",
    color: "rgba(0,0,0,0.74)",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "#FFCB00"
    },
    marginRight: "12px",
  },
  button: {
    borderRadius: "4px",
    backgroundColor: "#00376A",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    height: "49px",
    border: "unset",
    color: "#FFFFFF",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  expiryDialogWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 20px",
  },
  message: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginBottom: "25px",
  },
  saveOptionsContainer:{
    marginTop: "-25px",
    marginBottom: "20px"
  }
}));

const QuestionsUpload = ({ firebase, decodedToken }) => {
  const classes = useStyles();
  let {id} = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState(INITIAL_STATE);
  const [chapter, setChapter] = useState(null);
  const [year,setYear] = useState(2023);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [partialClear, setPartialClear] = useState(false);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [chapterList, setChapterList] = useState([]);


  // console.log('questionId: ', id);
  // console.log('chapter: ', chapter);

  
  const handleQuillChange = (value,name) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    }));
  }

  const modules = {
    toolbar: [
      [{ 'script': 'sub'}, { 'script': 'super' }],
    ],
  };


  const userEmail = firebase.auth.currentUser.email;

  // console.log("userUid: ", firebase.auth.currentUser.email);

  const handleChecked = (event) => {
    setFormState((formState) => ({
      ...formState,
      checked: {
        ...formState.checked,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const getCorrectAnswers = () =>
    Object.keys(formState.checked).filter(
      (correctAnswer) => formState.checked[correctAnswer] === true
    );

  const handleDelete = () => {
    setBtnDisabled(true);

    axios({
      method: 'delete',
      url: `${API_BASE_URL}/questions/${formState.values.questionId}`,
      headers: {Authorization: `Bearer ${decodedToken}`}})
      .then(() =>  {
        enqueueSnackbar("Întrebarea a fost stearsă cu succes.", {
          variant: "success",
        });

        setBtnDisabled(false);
        history.push("/questions")
      })
      .catch(error => {
        console.error('error: ', error)
        enqueueSnackbar("Întrebarea nu a fost stearsă.", {
          variant: "error",
        });
        setBtnDisabled(false);
      });
  }

  const handleUpdate = () => {
    const questionObj = {
      question: formState.values.question,
      questionId: formState.values.questionId,
      year: year,
      chapter: chapter.id,
      options: [
        formState.values.A,
        formState.values.B,
        formState.values.C,
        formState.values.D,
        formState.values.E,
      ],
      correctAnswers: getCorrectAnswers(),
    };

    if (
      !(
        questionObj.questionId &&
        questionObj.question &&
        questionObj.chapter &&
        questionObj.year &&
        !!questionObj.correctAnswers.length &&
        questionObj.options[0] &&
        questionObj.options[1] &&
        questionObj.options[2] &&
        questionObj.options[3]
      )
    ) {
      enqueueSnackbar("Nu toate campurile au fost completate", {
        variant: "error",
      });
      console.log('Missing fields')
      return;
    }

    setBtnDisabled(true);

    axios({
      method: 'put',
      url: `${API_BASE_URL}/questions`,
      data: {
        question: questionObj
      },
      headers: {Authorization: `Bearer ${decodedToken}`}})
      .then(() =>  {
        enqueueSnackbar("Întrebarea a fost actualizată cu succes.", {
          variant: "success",
        });

        setBtnDisabled(false);
        history.push("/questions")
      })
      .catch(error => {
        console.error('error: ', error)
        enqueueSnackbar("Întrebarea nu a fost actualizată.", {
          variant: "error",
        });
        setBtnDisabled(false);
      });
  }

  const handleSave = () => {
    const questionObj = {
      question: formState.values.question,
      year: year,
      chapter: chapter.id,
      options: [
        formState.values.A,
        formState.values.B,
        formState.values.C,
        formState.values.D,
        formState.values.E,
      ],
      correctAnswers: getCorrectAnswers(),
    };

    if (
      !(
        questionObj.question &&
        questionObj.chapter &&
        questionObj.year &&
        !!questionObj.correctAnswers.length &&
        questionObj.options[0] &&
        questionObj.options[1] &&
        questionObj.options[2] &&
        questionObj.options[3]
      )
    ) {
      enqueueSnackbar("Nu toate campurile au fost completate", {
        variant: "error",
      });
      console.log('Missing fields')
      return;
    }

    setBtnDisabled(true);

    axios({
      method: 'post',
      url: `${API_BASE_URL}/questions`,
      data: {
        question: questionObj
      },
      headers: {Authorization: `Bearer ${decodedToken}`}})
      .then(() =>  {
        enqueueSnackbar("Întrebarea a fost salvată cu succes.", {
              variant: "success",
            });

        if(partialClear){
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              question: "",

            },
            checked: {
              A: false,
              B: false,
              C: false,
              D: false,
              E: false,
            },
          }));
        } else{
          setFormState(INITIAL_STATE);
        }

        setBtnDisabled(false);
      })
      .catch(error => {
        console.error('error: ', error)
        enqueueSnackbar("Întrebarea nu a fost salvată.", {
                variant: "error",
              });
        setBtnDisabled(false);
      });
  };

  const renderOptionsFields = (formState) => {
    const options = Object.keys(formState.checked);
    return options.map((option, index) => (
      <Grid key={option + index} container>
        <Grid item xs={6} sm={6}>
        <FormControl className={classes.quillText} fullWidth variant="standard">
            <InputLabel htmlFor="component-question"><b>{`Varianta ${option}`}</b></InputLabel>
            <ReactQuill
                  id="component-question"
                  value={formState.values[option]}
                  onChange={event => handleQuillChange(event,option)}
                  modules={modules}
                  />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div className={classes.switchContainer}>
            <Switch
              checked={formState.checked[option]}
              onChange={handleChecked}
              name={option}
            />
            <Typography
              className={clsx(classes.switchText, {
                [classes.switchTextDisabled]: !formState.checked[option],
              })}
            >
              {formState.checked[option] ? "CORECT" : "INCORECT"}
            </Typography>
          </div>
        </Grid>
      </Grid>
    ));
  };

  useEffect(() => {
    if(!decodedToken){
      return;
    }
    axios({
      method: 'get',
      url: `${API_BASE_URL}/chapters/upload`,
      headers: {Authorization: `Bearer ${decodedToken}`}})
      .then(result => {
        const chapterListForAutocomplete = result.data.map(chapter => ({label: chapter["chapter_title"], id:chapter["chapter_id"]}));
        setChapterList(chapterListForAutocomplete);
        return chapterListForAutocomplete;
      }).then((chapterListForAutocomplete) => {
        if(!id){
        return;
        }
        return axios({
        method: 'get',
        url: `${API_BASE_URL}/questions?filters[id]=${id}`,
        headers: {Authorization: `Bearer ${decodedToken}`}})
        .then((result) => {
          const questionObject = result.data[0];
          const questionOptions = questionObject['question_options'];
          const questionAnswers = questionObject['correct_answers'].reduce((acc,current) => {
            return {...acc, [current]:true}
          }, {});

          const chapter = chapterListForAutocomplete.find(chapter => chapter.id == questionObject['chapter_id']);

          setChapter(chapter);

          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              questionId: questionObject['question_id'],
              question: escapeHTMLTags(questionObject.question),
              A: escapeHTMLTags(questionOptions[0]),
              B: escapeHTMLTags(questionOptions[1]),
              C: escapeHTMLTags(questionOptions[2]),
              D: escapeHTMLTags(questionOptions[3]),
              E: escapeHTMLTags(questionOptions[4]),
            },
            checked: {
              ...formState.checked,
              ...questionAnswers
            },
          }));
        })
      })
      .catch(error => console.error('error: ', error));
  }, [decodedToken]);

  if( !ALLOWED_EMAILS.includes(userEmail)){
    return <h1>Access respins</h1>
  }

  if (!chapterList.length) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Grid container>
        <Grid item xs={12} sm={12}>
            <FormControl className={classes.quillText} fullWidth variant="standard">
            <InputLabel htmlFor="component-question"><b>Întrebare</b></InputLabel>
            <ReactQuill 
                  id="component-question"
                  value={formState.values['question']}
                  onChange={event => handleQuillChange(event, 'question')}
                  modules={modules}
                  />
          </FormControl>
          </Grid>
          {renderOptionsFields(formState)}
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              className={classes.saveOptionsContainer}
              control={<Checkbox checked={partialClear} onChange={event => setPartialClear(event.target.checked)} name="checkedA" />}
              label="Pastrează variantele de răspuns"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
          <Autocomplete
            value={chapter}
            onChange={(event, newValue) => {
              setChapter(newValue);
            }}
            fullWidth={true}
            id="chapter-box"
            options={chapterList}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => <TextField fullWidth {...params} label="Capitolul" variant="outlined" />}
          />
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className={classes.saveSection}>
              {
                id ? (
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.cancelButton}
                    onClick={() => setConfirmationDialog(true)}
                    disabled={isBtnDisabled}
                  >
                    Sterge
                  </Button>
                ) : null
              }
              <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={id ? handleUpdate :handleSave}
                disabled={isBtnDisabled}
              >
                {id ? 'ACTUALIZEAZĂ' : 'SALVEAZĂ'}
              </Button>
            </div>
          </Grid>
        </Grid>
        <Dialog open={confirmationDialog}>
          <Card className={classes.expiryDialogWrapper}>
            <Typography className={classes.message} align="center">
              Sunteți sigur ca doriți să stergeți această întrebare?
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.explanationButton}
                color="primary"
                variant="contained"
                onClick={() => setConfirmationDialog(false)}
              >
                Înapoi
              </Button>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={handleDelete}
              >
                  Continuă
              </Button>
            </div>
          </Card>
        </Dialog>
      </Card>
    </div>
  );
};

export default compose(withFirebase, withAuthorization)(QuestionsUpload);
