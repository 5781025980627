import React from "react";
import { makeStyles, Card, Typography, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "110px",
    width: "768px",
    height: "auto",
    padding: "34px 58px 34px 58px",
  },
  continueButton: {
    height: "49px",
    width: "188px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  title: {
    fontSize: "36px",
    fontFamily: "Roboto",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    fontWeight: 500,
    marginBottom: "40px",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    fontWeight: 500,
    marginBottom: "66px",
  },
  icon: {
    height: "84px",
    width: "84px",
    marginBottom: "55px",
    color: "#FF9962",
  },
}));

const PaymentSuccess = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CheckCircleIcon className={classes.icon} />
        <Typography className={classes.title}>Felicitări!</Typography>
        <Typography className={classes.text} align="center">
          Ai primit acces nelimitat pentru rezolvarea grilelor pe platforma
          ReziExpress. În câteva minute va fi activat contul tău premium.
        </Typography>
        <Button
          className={classes.continueButton}
          color="primary"
          variant="contained"
          onClick={() => history.push("/dashboard")}
        >
          CONTINUĂ
        </Button>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
