import React from "react";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const PasswordStage = ({
  classes,
  hasError,
  formState,
  handleChange,
  showPassword,
  showConfirmPassword,
  handleClickShowPassword,
  handleClickShowConfirmPassword,
  handleSignUp,
}) => {
  return (
    <>
      <Typography
        className={clsx(classes.loginText, classes.registerTextMarginBottom)}
        align="center"
      >
        Crează cont
      </Typography>
      <Typography className={classes.emailText} align="center">
        {formState.values.email}
      </Typography>
      <TextField
        className={classes.textField}
        error={hasError("lastName")}
        fullWidth
        helperText={hasError("lastName") ? formState.errors.lastName[0] : null}
        name="lastName"
        label="Nume"
        onChange={handleChange}
        type="text"
        value={formState.values.lastName || ""}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError("firstName")}
        fullWidth
        helperText={
          hasError("firstName") ? formState.errors.firstName[0] : null
        }
        name="firstName"
        label="Prenume"
        onChange={handleChange}
        type="text"
        value={formState.values.firstName || ""}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError("password")}
        fullWidth
        helperText={hasError("password") ? formState.errors.password[0] : null}
        name="password"
        label="Parolă"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={!showPassword ? "password" : "text"}
        value={formState.values.password || ""}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        error={hasError("confirmPassword")}
        fullWidth
        helperText={
          hasError("confirmPassword")
            ? formState.errors.confirmPassword[0]
            : null
        }
        name="confirmPassword"
        label="Confirmă Parolă"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={!showConfirmPassword ? "password" : "text"}
        value={formState.values.confirmPassword || ""}
        variant="outlined"
      />
      <Button
        className={classes.signInButton}
        color="primary"
        variant="contained"
        disabled={!formState.isValid}
        fullWidth
        onClick={handleSignUp}
      >
        Continuă
      </Button>
    </>
  );
};

export default PasswordStage;
