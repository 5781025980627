import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import SimpleChapterCard from "../SimpleChapterCard";
import SubChapterCard from "../SubChapterCard";

const useStyles = makeStyles(() => ({
  selectedCards: {
    display: "flex",
    alignItems: "center",
    marginBottom: "72px",
    width: "976px",
    overflowX: "auto",
    "@media only screen and (max-width: 1200px)":{
      width:"100%",
    }
  },
}));

const SubChapterSelectSection = ({
  subjects,
  filter,
  setQuizQuestionsNoVisibility,
}) => {
  const classes = useStyles();
  const selectedSubjectIds = Object.keys(filter).map(Number);

  const [chapters, setChapters] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(
    selectedSubjectIds[0]
  );

  const selectedSubjectCards = subjects.map((subject, index) =>
    selectedSubjectIds.includes(subject.id) ? (
      <SimpleChapterCard
        key={subject.id + index}
        id={subject.id}
        title={subject.title}
        subtitle={subject.chapters}
        cardBackgroundColor={subject.backgroundColor}
        cardBackgroundUrl={subject.backgroundUrl}
        onClick={setSelectedSubject}
        selected={selectedSubject === subject.id}
      />
    ) : null
  );

  const chapterCards = chapters.map((subChapter, index) => (
    <Grid key={subChapter.id + index} item xs={12} sm={6} md={4}>
      <SubChapterCard
        chapterId={selectedSubject}
        subChapterId={subChapter.id}
        title={subChapter.chapterTitle}
        questionsNo={subChapter.questionsNo}
        order={subChapter.order}
      />
    </Grid>
  ));

  useEffect(() => {
    const hasSubSelectedSubChapters = selectedSubjectIds.some((element) => {
      return filter[element].length > 0;
    });
    setQuizQuestionsNoVisibility(hasSubSelectedSubChapters);
  }, [subjects, selectedSubjectIds, filter, setQuizQuestionsNoVisibility]);

  useEffect(() => {
    const chapters = subjects.find(
      (chapter) => chapter.id === selectedSubject
    ).subChaptersRef;

    setChapters(chapters);
  }, [subjects, selectedSubject]);


  return (
    <Fragment>
      <Grid item xs={12} sm={12}>
        <div className={classes.selectedCards}>{selectedSubjectCards}</div>
      </Grid>
      {chapterCards}
    </Fragment>
  );
};

export default SubChapterSelectSection;
