import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Typography,
} from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    height: "132px",
    minWidth: "328px",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "24px",
    "@media only screen and (max-width: 400px)": {
      minWidth: "250px",
    },
  },
  title: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "20px",
    marginBottom: "12px",
  },
  cardActionArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: "100%",
    width: "100%",
    padding: "16px",
  },
  checkIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    height: "24px",
    wdith: "24px",
    color: "#FF9962",
  },
  price: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "34px",
    letterSpacing: 0,
    lineHeight: "36px",
    marginBottom: "6px",
  },
  disabled: {
    opacity: 0.5,
    cursor: "unset",
  },
}));

const SubscriptionCard = ({
  title,
  price,
  description,
  selected,
  onClick,
  disabled,
  hide,
}) => {
  const classes = useStyles();
  const [raised, setRaised] = useState(false);

  const toggleHoverRaised = () => {
    if (disabled) {
      return;
    }
    setRaised(!raised);
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick(price);
  };

  if (hide) {
    return null;
  }

  return (
    <Card
      className={clsx(classes.card, { [classes.disabled]: disabled })}
      raised={raised}
      onMouseEnter={toggleHoverRaised}
      onMouseLeave={toggleHoverRaised}
      onClick={handleClick}
    >
      <CardActionArea
        className={classes.cardActionArea}
        style={{
          backgroundColor: selected ? "rgba(255, 153, 98, 0.16)" : "unset",
        }}
        disableRipple={disabled}
      >
        <div className={classes.cardActions}>
          <Typography noWrap title={title} className={classes.title}>
            {title}
          </Typography>
          <Typography noWrap title={title} className={classes.price}>
            {price + " RON"}
          </Typography>
          <Typography noWrap title={title} className={classes.title}>
            {description}
          </Typography>
        </div>

        {!!selected && (
          <div className={classes.checkIcon}>
            <CheckCircleIcon />
          </div>
        )}
      </CardActionArea>
    </Card>
  );
};

export default SubscriptionCard;
