import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Typography,
} from "@material-ui/core";
import Grow from "@material-ui/core/Zoom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { useSubChapterValue } from "../FilterGroup";

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
    height: "100px",
    minWidth: "234px",
    marginBottom: "30px",
    marginLeft: "15px",
    marginRight: "15px",
    cursor: "pointer",
  },
  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500px",
    lineHeight: "24px",
    width: "230px",
    letterSpacing: "0.15px",
    textOverflow: "ellipsis",
  },
  cardActionArea: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "150px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  checkIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    height: "24px",
    wdith: "24px",
    color: "#2B3058",
  },
  subtitle: {
    color: "rgba(0,0,0,0.6)",
    marginLeft: "0px !important",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
}));

const SubChapterCard = ({
  chapterId,
  subChapterId,
  title,
  questionsNo,
  order,
}) => {
  const [raised, setRaised] = useState(false);
  const [
    selectedSubChapter,
    selectSubChapter,
    removeSubChapter,
  ] = useSubChapterValue(chapterId, subChapterId, undefined);

  const toggleHoverRaised = () => setRaised(!raised);

  const classes = useStyles();
  return (
    <Grow in={true} timeout={(order + 1) * 150}>
      <Card
        className={classes.card}
        raised={raised}
        onMouseEnter={toggleHoverRaised}
        onMouseLeave={toggleHoverRaised}
        onClick={() =>
          selectedSubChapter
            ? removeSubChapter(subChapterId)
            : selectSubChapter(subChapterId)
        }
      >
        <CardActionArea
          className={classes.cardActionArea}
          style={{
            backgroundColor: !!selectedSubChapter
              ? "rgba(43, 48, 88, 0.2)"
              : "unset",
          }}
        >
          <div className={classes.cardActions}>
            <Typography noWrap title={title} className={classes.title}>
              {title}
            </Typography>
            <Typography className={classes.subtitle}>
              {questionsNo + " întrebări"}
            </Typography>
          </div>

          {!!selectedSubChapter && (
            <div className={classes.checkIcon}>
              <CheckCircleIcon />
            </div>
          )}
        </CardActionArea>
      </Card>
    </Grow>
  );
};

export default SubChapterCard;
