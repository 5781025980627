import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout, PrivateRouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  QuestionsUpload as QuestionsUploadView,
  Dashboard as DashboardView,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Examination as ExaminationView,
  Questions as QuestionsView,
  Contact as ContactView,
  News as NewsView,
  TipsAndTricks as TipsAndTricksView,
  FAQ as FaqView
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <PrivateRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
       <RouteWithLayout
        component={ContactView}
        exact
        layout={MinimalLayout}
        path="/contact"
      />
      <PrivateRouteWithLayout
        component={QuestionsUploadView}
        layout={MainLayout}
        path="/questions/new"
        exact
      />
      <PrivateRouteWithLayout
        component={QuestionsUploadView}
        layout={MainLayout}
        path="/questions/edit/:id"
        exact
      />
      <PrivateRouteWithLayout
        component={QuestionsView}
        layout={MainLayout}
        path="/questions"
        exact
      />
      <PrivateRouteWithLayout
        component={ExaminationView}
        layout={MainLayout}
        path="/quiz/:id"
        exact
      />
      <PrivateRouteWithLayout
        component={NewsView}
        exact
        layout={MainLayout}
        path="/news"
      />
      <PrivateRouteWithLayout
        component={TipsAndTricksView}
        exact
        layout={MainLayout}
        path="/tips-and-tricks"
      />
      <PrivateRouteWithLayout
        component={FaqView}
        exact
        layout={MainLayout}
        path="/faq"
      />
      <PrivateRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
