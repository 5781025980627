import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Typography,
  Grow,
} from "@material-ui/core";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    height: "146px",
    width: "240px",
    borderRadius: "4px",
    borderTop: "8px solid rgb(255, 203, 0)",
    cursor: "pointer",
    margin: "16px",
    "@media only screen and (max-width: 400px)": {
      minWidth: "250px",
    },
  },
  cardActionArea: {
    display: "flex",
    flexWrap:"wrap",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "rgba(163, 195, 217, 0.4)",
    height: "100%",
    width: "100%",
    padding: "24px 16px",
  },
  title: {
    color: "rgb(27, 48, 88)",
    fontFamily: "Roboto",
    fontSize: "28px",
    fontWeight:500,
    lineHeight: "36px",
    marginBottom: "6px",
  },
  subTitle: {
    color: "rgb(27, 48, 88)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight:400,
    lineHeight: "20px",
    letterSpacing:"0.25"
  },
}));

const DashboardCard = ({
  title,
  description,
  onClick,
  order
}) => {
  const classes = useStyles();
  const [raised, setRaised] = useState(false);

  const toggleHoverRaised = () => {
    setRaised(!raised);
  };

  return (
    <Grow in={true} timeout={(order + 1) * 150}>
    <Card
      className={clsx(classes.card)}
      raised={raised}
      onMouseEnter={toggleHoverRaised}
      onMouseLeave={toggleHoverRaised}
      onClick={onClick}
    >
      <CardActionArea
        className={classes.cardActionArea}
      >
        <div className={classes.cardActions}>
          <Typography title={title} className={classes.title}>
            {title}
          </Typography>
          <Typography noWrap title={description} className={classes.subTitle}>
            {description}
          </Typography>
        </div>
      </CardActionArea>
    </Card>
    </Grow>
  );
};

export default DashboardCard;
