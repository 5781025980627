import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  termsAndConditions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "110px",
  },

  termsAndConditionsCard: {
    width: "768px",
  },

  termsAndConditionsCardContent: {
    padding: "35px 25px",
  },

  title: {
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: "0.26px",
    lineHeight: "24px",
    marginBottom: "50px",
  },

  body: {
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    maxHeight: "450px",
    overflow: "auto",
  },
  icon: {
    height: "40px",
    width: "40px",
  },
  answerButton: {
    height: "49px",
    width: "188px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.termsAndConditions}>
      <Card className={classes.termsAndConditionsCard}>
        <IconButton onClick={() => history.push("/")}>
          <ArrowBackIcon className={classes.icon} />
        </IconButton>
        <CardContent className={classes.termsAndConditionsCardContent}>
          <Typography className={classes.title} align="center">
            Termeni și condiții
          </Typography>
          <Typography className={classes.body}>
            <b>1. Definitii si Termeni folositi</b> <br />
            <br />
            1.1 Administrator - Asociatia Non Profit pentru A.T.I. „Aurel
            Mogoseanu” cu sediul in Municipiul Timisoara, Strada Salcamilor, Nr.
            17, Judet Timis, CIF 15675154 <br />
            <br />
            1.2 ReziExpress.ro - include toate sectiunile de pe domeniul
            https://reziexpress.ro/ <br />
            <br />
            1.3 Utilizator - orice persoana fizica (sau juridica), ce acceseaza
            ReziExpress.ro <br />
            <br />
            1.4 Serviciu - serviciu care poate fi comercializat conform legii
            <br />
            <br />
            1.5 Contract - contractul la distanta incheiat intre Administrator
            si Utilizator, fara prezenta fizica simultana a Administratorului si
            a Utilizatorului.
            <br />
            <br /> <b>2. Acceptarea Termenilor si Conditiilor</b> <br />
            <br />
            2.1 Prin accesarea site-ului Reziexpress.ro sunteti de acord cu
            Termenii si Conditiile de mai jos. <br />
            <br />
            <b>3. Termeni Generali </b>
            <br />
            <br />
            3.1 Administratoul isi rezerva dreptul de a modifica continutul
            site-ului ReziExpress.ro in orice moment fara a necesita un acord
            prealabil si fara o notificare catre utilizatori.
            <br />
            <br />
            3.2 Utilizatorul nu are dreptul de a descarca, de a reproduce sau de
            a copia site-ul ReziExpress.ro sau de a abuza de anumite erori
            tehnice.
            <br />
            <br /> 3.3 Administratoul sau site-ul ReziExpress.ro nu vor fi
            responsabili de nici o dauna directa sau indirecta provocata de
            utilizatori, din orice cauza. <br />
            <br />
            3.4 Adminstratorul nu isi asuma raspunderea pentru problemele
            tehnice a celorlalte terte cu care aceasta lucreaza.
            <br />
            <br /> 3.5 Site-ul ReziExpress.ro respecta confidentialitatea
            informatiilor care nu sunt publice, completate de utilizator la
            inregistrarea contului.
            <br />
            <br /> <b>4. Servicii</b>
            <br />
            <br /> 4.1 Administratorul si ReziExpress.ro nu garanteaza
            exactitatea informatiilor de pe site-ul ReziExpress.ro <br />
            <br />
            4.2 ReziExpress.ro sau Administratorul nu este raspunzatoar in
            niciun fel de pierderile sau daunele pe care utilizatorii le sufera
            ca urmare a folosirii in vreun fel a informatiilor de pe
            ReziExpress.ro.
            <br />
            <br />
            4.3 Pretul serviciilor este afisat in lei si poate fi modificat in
            orice moment de Administrator. <br />
            <br />
            <b>5. Modalitati de Plata </b>
            <br />
            <br />
            5.1 Plata se face online, cu cardul, procesarea se face prin
            intermediul{" "}
            <a href="https://www.euplatesc.ro/" target="_blank" rel="noopener noreferrer">
              https://www.euplatesc.ro/
            </a>
            , iar noi nu vom stoca niciun fel de date referitoare la cardul
            dumneavoastră.
            <br />
            <br />
            5.2 Instituția emitentă a cardului va proceda la validarea și
            autorizarea plății. În situația în care, tranzacția nu se procesează
            din motive independente nouă, contractul dintre noi nu se încheie și
            nu vom avea nicio obligație de livrare a produsele.
            <br />
            <br />
            <b>6. Politica de Anulare/Returnare</b>
            <br />
            <br /> 6.1 Administratorul nu va oferi rambursari pentru platile
            efectuate pe ReziExpress.ro.
            <br />
            <br />
            <b>7. Drepturile de Autor</b>
            <br />
            <br />
            7.1 ReziExpress.ro isi rezerva drepturile de autor depline asupra
            tuturor resurselor site-ului precum si asupra tuturor informatiilor
            introduse de Administrator.
            <br />
            <br /> <b>8. Erori</b>
            <br />
            <br /> 8.1 Administratorul nu va putea fi facut responsabil pentru
            eventualele erori aparute pe site isau pentru erorile aparute din
            cauza folosirii anumitor browsere pentru vizitarea ReziExpress.ro.
            <br />
            <br />
            <b>9. Cookie-uri</b>
            <br />
            <br />
            9.1 ReziExpress.ro poate utiliza cookies pentru procesul de
            conectare si nu pentru a stoca informatii cu caracter personal
            despre utilizatori pentru reclame sau pentru a furniza datele.
            <br />
            <br /> <b>10. Datele cu Caracter Personal si Confidentialitatea</b>
            acestora
            <br />
            <br /> 10.1 Conform Legii nr. 677/2001 pentru protectia persoanelor
            cu privire la prelucrarea datelor cu caracter personal si libera
            circulatie a acestor date, modificata si completata si ale Legii nr.
            506/2004 privind prelucrarea datelor cu caracter personal si
            protectia vietii private in sectorul comunicatiilor electronice
            Administratorul are obligatia de a administra in conditii de
            siguranta si numai pentru scopurile specificate datele personale pe
            care ni le furnizati despre dumneavoastra.
            <br />
            <br />
            10.2 Administratorul si ReziExpress.ro va retine datele personale si
            le va folosi in scopul informarii utilizatorilor privind situatia
            contului de pe ReziExpress.ro.
            <br />
            <br />
            10.3 Utilizatorul este in intregime responsabil pentru pastrarea
            confidentialitatii asupra contului si parolei pentru conectarea la
            ReziExpress.ro
            <br />
            <br />
            10.4 In cazul accesarii neautorizate a contului de catre alte
            persoane, Administratorul nu isi asuma nici o responsabilitate fata
            de consecintele ce pot aparea.
            <br />
            <br /> <b>10. Dispozitii Finale </b>
            <br />
            <br />
            10.1 Administratorul are dreptul de a modifica aceste reguli in
            orice moment. Orice modificare va fi facuta publica in aceasta
            pagina si va intra in vigoare din momentul postarii ei pe
            ReziExpress.ro.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default TermsAndConditions;
