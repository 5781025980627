import React, { useEffect, useState } from "react";
import { withFirebase } from "../../Firebase";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, Card, Typography, Dialog, Hidden } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {API_BASE_URL} from "../../common/constants";
import {
  SectionHeader,
  SubjectSelectSection,
  SubChapterSelectSection,
  ArrowButton,
  FilterGroup,
  QuizSelectQuestionsNumber,
  NotificationBox,
} from "./components";


import CircularProgress from "@material-ui/core/CircularProgress";

import Loading from "../../components/Loading";
import { omit } from "lodash";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    width: "976px",
    flexWrap: "wrap",
    "@media only screen and (max-width: 1200px)":{
      width:"100%",
    }
  },
  gridContainer:{
    margin: "0 15px"
  },
  expiryDialogWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 20px",
  },
  title: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: "0.26px",
    lineHeight: "24px",
    marginBottom: "25px",
  },
  message: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginBottom: "25px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    borderRadius: "4px",
    backgroundColor: "#FFCB00",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    height: "49px",
    border: "unset",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "rgba(255, 203, 0, 1)",
    },
  },
  explanationButton: {
    height: "49px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
    color: "rgba(0,0,0,0.74)",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "unset",
    },
    marginRight: "12px",
  },
  customArrowButtons:{
    marginLeft: "16px",
    padding: 0,
    height: "48px",
    width: "128px",
    backgroundColor: "#2B3058",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    borderRadius: "30px",
    "&:hover":{
      backgroundColor: "#2B3058"
    }
  },
  loadingWrapper: {
    minHeight: "100%",
    position: "relative",
  },
  loading: {
    color: "#FFFFFF",
  },
}));

const Dashboard = ({firebase, makeRequest, decodedToken}) => {
  const classes = useStyles();
  const history = useHistory();

  const [stage, setStage] = useState(0);
  const [isWithTimer, setIsWithTimer] = useState(false);
  const [timerValue, setTimerValue] = useState(30);
  const [isSubscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [isQuestionsDialogOpen, setQuestionsDialogOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [hasUnfinishedQuiz, setHasUnfinishedQuiz] = useState(false);
  const [quizQuestionsNo, setQuizQuestionNo] = useState(10);
  const [selectedQuestionsNo, setSelectedQuestionsNo] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [quizQuestionsNoVisibility, setQuizQuestionsNoVisibility] = useState(
      false
  );

  const {uid, displayName} = firebase.auth.currentUser;
  const parsedDisplayName = JSON.parse(displayName);

  const removeChapter = (id) => setFilter((prevState) => omit(prevState, id));
  const onNext = () => setStage(1);
  const onBack = () => setStage(0);

  const onContinueQuizz = () => history.push("/settings");

  const onCreateNewQuiz = () => {
    setButtonDisabled(true);
    const selectedChapters = Object.keys(filter).reduce(
        (total, currentValue) => {
          return (total = [...total, ...filter[currentValue]]);
        },
        []
    );

    makeRequest({
      method: 'post',
      url: `${API_BASE_URL}/quiz`,
      data: {
        selectedChapters,
        quizQuestionsNo
        }})
        .then((result) => {
          // console.log('CREATE QUIZ: ', result);
          history.push(`/quiz/${result.data.quizId}`);
        })
        .catch((error) => {
          console.error("Create new quiz: ", error);
          setButtonDisabled(false);
        });
  };

  const mapChapters = (chapter) => {
    return ({
      id: chapter['chapter_id'],
      order: chapter['chapter_order'],
      questionsNo: chapter['question_count'],
      chapterTitle: chapter['chapter_title'],
      subjectId: chapter['subject_id']
    });
  }

  const handleCreateQuiz = () => {
    const selectedChapters = Object.keys(filter).reduce(
        (total, currentValue) => {
          return (total = [...total, ...filter[currentValue]]);
        },
        []
    );

    const selectedQuestionsNo = selectedChapters.reduce(
        (accumulator, currentValue) => {
          const questionsNo = chapters.filter(
              (chapter) => chapter.id === currentValue
          );
          return accumulator + questionsNo[0].questionsNo;
        },
        0
    );

    if (selectedQuestionsNo < quizQuestionsNo) {
      setSelectedQuestionsNo(selectedQuestionsNo);
      setQuestionsDialogOpen(true);
      return;
    }
    onCreateNewQuiz();
  };

  useEffect(() => {
    if(!decodedToken){
      return;
    }

    makeRequest({
      method: 'get',
      url: `${API_BASE_URL}/quiz/active`,
      })
      .then(result => {
        const hasActiveQuiz = !!result.data.count;
        setHasUnfinishedQuiz(hasActiveQuiz);
      })
      .catch(error => console.error(error));


    makeRequest({
      method: 'get',
      url: `${API_BASE_URL}/chapters`,
      })
      .then(result => {
        const subjects = [];

        const allChapters = result.data.map(mapChapters);
        const chemistryChapters = allChapters.filter(chapter => chapter.subjectId === 2);
        const biologyChapters = allChapters.filter(chapter => chapter.subjectId === 1);

        subjects.push({
          id: 1,
          title: "Biologie",
          chapters: 13,
          backgroundUrl: '/images/biology2.jpg',
          backgroundColor: '#FFFFFF',
          subChaptersRef:biologyChapters,
          order:0
        });

        subjects.push({
          id: 2,
          title: "Chimie",
          chapters: 13,
          backgroundUrl: '/images/chemistry3.jpg',
          backgroundColor: '#FFFFFF',
          subChaptersRef:chemistryChapters,
          order:1
        });

        setSubjects(subjects);
        setChapters(allChapters);
      })
      .catch(error => console.error(error));
  }, [uid, decodedToken]);


  if (!chapters.length) {
    return <Loading />;
  }

  const isSelectMode = !!Object.keys(filter).length;

  return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Hidden mdDown>
          {isSelectMode && stage === 0 && (
              <ArrowButton position="right" text="Next" onClick={onNext} />
          )}
          {stage === 1 && (
              <ArrowButton position="left" text="Back" onClick={onBack} />
          )}
          </Hidden>
          <FilterGroup
              value={filter}
              setValue={setFilter}
              removeChapter={removeChapter}
          >
            <Grid container>
              <Grid item xs={12} >
                <SectionHeader
                    firstName={parsedDisplayName?.firstName}
                    title={
                      stage === 0
                          ? "Alege grilele din care dorești să iți creezi propriul test"
                          : "Alege capitolele din care dorești să iți creezi propriul test"
                    }
                />
                <Hidden lgUp>
                <NotificationBox
                  description={"Ai un test neterminat, dorești să continui?"}
                  buttonText={"continuă"}
                  visibility={hasUnfinishedQuiz}
                  onClick={onContinueQuizz}
                  absolute={false}
                />
                </Hidden>
                <Hidden lgUp>
                  <QuizSelectQuestionsNumber
                    visibility={quizQuestionsNoVisibility && stage === 1}
                    quizQuestionsNo={quizQuestionsNo}
                    setQuizQuestionNo={setQuizQuestionNo}
                    onClick={handleCreateQuiz}
                    isButtonDisabled={buttonDisabled}
                    absolute={false}
                  />
                </Hidden>
              </Grid>
              <Grid item xs={12}>
                <Hidden lgUp>
                  {isSelectMode && stage === 0 && (
                    <ArrowButton position="right" text="Next" customStyles={classes.customArrowButtons} onClick={onNext} />
                  )}
                  {stage === 1 && (
                    <ArrowButton position="left" text="Back" customStyles={classes.customArrowButtons} onClick={onBack} />
                  )}
                </Hidden>
              {stage === 0 && (
                  <SubjectSelectSection
                      subjects={subjects}
                      isSelectMode={isSelectMode}
                  />
              )}
              </Grid>
              {stage === 1 && (
                  <SubChapterSelectSection
                      subjects={subjects}
                      filter={filter}
                      setQuizQuestionsNoVisibility={setQuizQuestionsNoVisibility}
                  />
              )}
            </Grid>
          </FilterGroup>
        </div>
        <Hidden mdDown>
          <NotificationBox
            description={"Ai un test neterminat, dorești să continui?"}
            buttonText={"continuă"}
            visibility={hasUnfinishedQuiz}
            onClick={onContinueQuizz}
            absolute={true}
          />
        </Hidden>
        <Hidden mdDown>
        <QuizSelectQuestionsNumber
            visibility={quizQuestionsNoVisibility && stage === 1}
            timerValue={timerValue}
            setTimerValue={setTimerValue}
            isWithTimer={isWithTimer}
            setIsWithTimer={setIsWithTimer}
            quizQuestionsNo={quizQuestionsNo}
            setQuizQuestionNo={setQuizQuestionNo}
            onClick={handleCreateQuiz}
            isButtonDisabled={buttonDisabled}
            absolute={true}
        />
        </Hidden>
        <Dialog open={isSubscriptionDialogOpen}>
          <Card className={classes.expiryDialogWrapper}>
            <Typography className={classes.title} align="center">
              Abonamentul tău a expirat!
            </Typography>
            <Typography className={classes.message} align="center">
              Pentru a avea acces nelimitat la toate grilele platformei
              ReziExpress, va trebui sa cumperi un nou abonament.
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={() => history.push("/settings")}
              >
                Activează-ți abonamentul!
              </Button>
            </div>
          </Card>
        </Dialog>
        <Dialog open={isQuestionsDialogOpen}>
          <Card className={classes.expiryDialogWrapper}>
            <Typography className={classes.message} align="center">
              Capitolele alese au un număr total de {selectedQuestionsNo}{" "}
              întrebări. <br />
              Grila va conține {selectedQuestionsNo} întrebări.
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                  className={classes.explanationButton}
                  variant="outlined"
                  onClick={() => setQuestionsDialogOpen(false)}
              >
                Înapoi
              </Button>
              <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={() => onCreateNewQuiz()}
                  disabled={buttonDisabled}
              >
                {buttonDisabled ? (
                    <CircularProgress className={classes.loading} />
                ) : (
                    "Continuă"
                )}
              </Button>
            </div>
          </Card>
        </Dialog>
      </div>
  );
};

export default withFirebase(Dashboard);
