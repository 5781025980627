import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    marginBottom: "76px",
    marginLeft: "16px",
  },
  firstName: {
    color: "#1B3058",
    fontFamily: "Roboto",
    fontSize: "48px",
    letterSpacing: "0",
    lineHeight: "56px",
  },
  title: {
    marginTop: "12px",
    color: "#1B3058",
    fontFamily: "Roboto",
    fontSize: "25px",
    letterSpacing: "0.18px",
    lineHeight: "24px",
  },
}));

const SectionHeader = ({ firstName, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.firstName}>Salut {firstName}</Typography>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
};

export default SectionHeader;
