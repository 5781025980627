import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { AppBar, Toolbar, IconButton, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import { withFirebase } from "../../../../Firebase";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "70px",
    boxShadow:
      "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
    backgroundColor: "#FFFFFF",
  },
  flexGrow: {
    flexGrow: 1,
  },
  button: {
    marginLeft: "15px",
  },
  toolbar: {
    minHeight: "56px",
    display: "flex",
    alignItems: "center",
  },
  navImage:{
      marginTop: "2px",
      height: "60px",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    color: "#00376A",
  },
  active: {
    "& $icon": {
      color: "#FFCB00",
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const Topbar = ({ className, firebase, onSidebarOpen }) => {
  const classes = useStyles();

  const doSignOut = () => firebase.doSignOut();

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <img src="/images/logo-navigation.png" className={classes.navImage} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon className={classes.icon}/>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
        <CustomRouterLink activeClassName={classes.active} to="/dashboard">
          <IconButton className={classes.button}>
            <HomeIcon className={classes.icon} />
          </IconButton>
        </CustomRouterLink>
        <CustomRouterLink activeClassName={classes.active} to="/settings">
          <IconButton className={classes.button}>
            <AccountCircleIcon className={classes.icon} />
          </IconButton>
        </CustomRouterLink>
        <CustomRouterLink activeClassName={classes.active} to="/sign-in">
          <IconButton className={classes.button} onClick={doSignOut}>
            <ExitToAppIcon className={classes.icon} />
          </IconButton>
        </CustomRouterLink>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default withFirebase(Topbar);
