import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Grow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { withFirebase } from "../../Firebase";
import Loading from "../../components/Loading";
import Congratulations from "./Congratulations";
import Results from "./Results";

import { omit } from "lodash";

import CheckIcon from "@material-ui/icons/Check";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ClearIcon from "@material-ui/icons/Clear";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { API_BASE_URL,QUIZ_OPTIONS } from "../../common/constants";
import { escapeHTMLPTags, escapeOtherHTMLTags } from "../../common/utils";

const useStyles = makeStyles(() => ({
  examination: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "110px",
  },

  examinationCard: {
    width: "768px",
    "@media only screen and (max-width: 804px)":{
      width:"100%",
    }
  },

  examinationCardContent: {
    padding: "16px 24px 24px 24px",
  },

  questionNo: {
    color: "rgba(0,0,0,0.38)",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    marginBottom: "8px",
    fontFamily: "Roboto",
  },

  mainQuestion: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginBottom: "36px",
  },

  questionOptions: {
    paddingTop: "9px",
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
  },

  questionOptionsWrapper: {
    display: "flex",
    minHeight: "40px",
    alignItems: "flex-start",
  },

  buttonWrapper: {
    display: "flex",
    paddingTop: "15px",
    cursor: "pointer",
  },

  explanationCard: {
    marginTop: "32px",
    width: "768px",
  },

  explanationContent: {
    padding: "24px",
  },

  explanationText: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },

  answerButton: {
    height: "49px",
    width: "188px",
    borderRadius: "4px",
    backgroundColor: "#1B3058",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  },

  explanationButton: {
    height: "49px",
    width: "188px",
    borderRadius: "4px",
    backgroundColor: "#F8F8F8",
    color: "rgba(0,0,0,0.74)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  newQuizWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  newQuizContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  newQuizTitle: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: "0.26px",
    lineHeight: "24px",
    marginTop: "20px",
    marginBottom: "30px",
  },
  newQuizText: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginBottom: "30px",
  },
  icon: {
    height: "93px",
    width: "84px",
    color: "#FF9962",
  },
  selectedIcon: {
    backgroundColor: "rgba(255, 153, 98, 0.16) !important",
  },

  selectedAnswer: {
    backgroundColor: "#E0F2F1 !important",
  },
  correctAnswer: {
    backgroundColor: "#C8E6C9 !important",
  },
  wrongAnswer: {
    backgroundColor: "#FFCDD2 !important",
  },
}));

const Examination = ({ decodedToken, makeRequest }) => {
  const classes = useStyles();
  const history = useHistory();
  const {id} = useParams();

  const [loading, setLoading] = useState(true);

  const [showExamSection, setShowExamSection] = useState(true);
  const [showExplanationBtn, setShowExplanationBtn] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isEvaluated, setEvaluated] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [correctanswers, setCorrectAnswers] = useState([]);
  const [solvedQuestions, setSolvedQuestions] = useState(0);
  const [points, setPoints] = useState(0);

  const [singleAnswerQuestions, setSingleAnswerQuestions] = useState(0);
  const [solvedPercentage, setSolvedPercentage] = useState(0);

  // console.log("POINTS: ", points);
  // console.log("solvedPercentage: ", solvedPercentage);


  // console.log('questions: ',questions);
  // console.log('currentIndex: ',currentIndex);
  // console.log('correctanswers: ',correctanswers);
  // console.log('solvedQuestions: ',solvedQuestions);
  // console.log('points: ',points);

  const isSingleAnswerQuestion = correctanswers.length === 1;
  const maximumPointsForSelectedAnswer = 1 / correctanswers.length;

  const totalPoints = questions.length;

  const handleSelectedOption = (event) => {
    setSelectedOptions((prevState) =>
      prevState.hasOwnProperty(event)
        ? { ...omit(prevState, event) }
        : { ...prevState, [event]: { selected: true } }
    );
  };

  const getSelectedAnswers = answerObj => {
    const selectedAnswers = [];

    for (const [key, value] of Object.entries(answerObj)) {
      if(value?.selected === true){
        selectedAnswers.push(key);
      }
    }
    return selectedAnswers.sort();
  }

  const handleNext = () => {
    if (currentIndex === questions.length - 1) {
      setShowExplanation(false);
      setShowExamSection(false);
      setShowCongratulations(true);
      return;
    }

    setCurrentIndex((prevState) => prevState + 1);
  };

  /** Resets quiz data and sends user back to homepage. */
  const createNewQuiz = () => {
    history.push("/dashboard");
  };

  /** Evaluates question. */
  const handleEvaluateTest = () => {
    if (!Object.keys(selectedOptions).length) {
      return;
    }

    if (isEvaluated) {
      return handleNext(selectedOptions);
    }

    let currentPoints = [];

    const calculatePointsForSelectedOption = (pointsArray, option) => {
      //selected wrong answer, always failed quiz
      const selectedOptionKeys = Object.keys(selectedOptions);
      const difference = selectedOptionKeys.filter(selection => !correctanswers.includes(selection));

      if(difference.length){
        return currentPoints.push(0);
      }

      if (isSingleAnswerQuestion) { //this is still valid in current context
        if (
          selectedOptions.hasOwnProperty(option) &&
          correctanswers.includes(option) &&
          Object.keys(selectedOptions).length === 1
        ) {
          currentPoints.push(1);
        } else {
          currentPoints.push(0);
        }
      } else {
        if (//this is still valid in current context
          Object.keys(selectedOptions).length > 4
        ) {
          return currentPoints.push(0);
        }
        if (
          selectedOptions.hasOwnProperty(option) &&
          correctanswers.includes(option)
        ) {
          return pointsArray.push(maximumPointsForSelectedAnswer);
        } else if (
          selectedOptions.hasOwnProperty(option) &&
          !correctanswers.includes(option)
        ) {
          return pointsArray.push(0);
        } else if (
          !selectedOptions.hasOwnProperty(option) &&
          !correctanswers.includes(option)
        ) {
          return pointsArray.push(0);
        } else if (
          !selectedOptions.hasOwnProperty(option) &&
          correctanswers.includes(option)
        ) {
          return pointsArray.push(0);
        }
      }
    };

    const correctedAnswers = QUIZ_OPTIONS.reduce((correctedAnswers, option) => {
      calculatePointsForSelectedOption(currentPoints, option);

      if (
        selectedOptions.hasOwnProperty(option) &&
        !correctanswers.includes(option)
      ) {
        return {
          ...correctedAnswers,
          [option]: { ...selectedOptions[option], correctAnswer: false },
        };
      }

      if (
        selectedOptions.hasOwnProperty(option) &&
        correctanswers.includes(option)
      ) {
        return {
          ...correctedAnswers,
          [option]: {
            ...selectedOptions[option],
            correctAnswer: true,
          },
        };
      }

      if (
        !selectedOptions.hasOwnProperty(option) &&
        correctanswers.includes(option)
      ) {
        return {
          ...correctedAnswers,
          [option]: {
            ...selectedOptions[option],
            notSelected: true,
          },
        };
      }

      return {
        ...correctedAnswers,
        [option]: {
          ...selectedOptions[option],
        },
      };
    }, {});

    let accumulatedPoints = currentPoints.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const currentSingleAnswerQuestions = isSingleAnswerQuestion
      ? singleAnswerQuestions + 1
      : singleAnswerQuestions;

    const currentSolvedQuestions =
      accumulatedPoints > 0 ? solvedQuestions + 1 : solvedQuestions;

    const finalPoints = points + accumulatedPoints;

    const currentQuizCompletePercentage =   parseInt((finalPoints / questions.length ) * 100);

    setSingleAnswerQuestions(currentSingleAnswerQuestions);
    setSolvedQuestions(currentSolvedQuestions);
    setSelectedOptions(correctedAnswers);
    setEvaluated(true);
    setPoints(finalPoints);
    setSolvedPercentage(currentQuizCompletePercentage);

    const selectedAnswers = getSelectedAnswers(selectedOptions);
    const questionId = questions[currentIndex].questionId;
    questions[currentIndex].selectedAnswers = selectedAnswers;

    const isResolved = currentIndex === questions.length - 1;

    const currentProgress = {
        questionId,
        currentIndex: currentIndex + 1,
        solvedQuestions:currentSolvedQuestions,
        singleAnswerQuestions:currentSingleAnswerQuestions,
        points:finalPoints,
        selectedAnswers,
        solvedPercentage:currentQuizCompletePercentage,
        isResolved: isResolved
    }

    makeRequest({
      method: 'put',
      url: `${API_BASE_URL}/quiz/${id}`,
      data: {
        ...currentProgress
      },
      })
  };

  const generateOptions = (options) =>
    options.map((option, index) => {
      const option_index = QUIZ_OPTIONS[index];

      const displayError =
        selectedOptions?.[option_index]?.correctAnswer === false &&
        selectedOptions?.[option_index]?.selected === true;

      return (
        <FormControlLabel
          className={classes.questionOptionsWrapper}
          key={option + index}
          onChange={() => handleSelectedOption(option_index)}
          disabled={isEvaluated}
          control={
            <Checkbox
              name={option_index}
              icon={
                selectedOptions[option_index]?.correctAnswer === true ||
                selectedOptions[option_index]?.notSelected ? (
                  <CheckIcon
                    style={{
                      color:
                        selectedOptions[option_index]?.notSelected === true
                          ? "rgba(0,0,0,0.87)"
                          : "rgba(255, 153, 98, 1)",
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              checkedIcon={
                displayError ? (
                  <ClearIcon style={{ color: "rgba(0,0,0,0.87)" }} />
                ) : selectedOptions[option_index]?.correctAnswer === true ? (
                  <CheckIcon style={{ color: "#FFCB00" }} />
                ) : (
                  <CheckBoxIcon style={{ color: "#FFCB00" }} />
                )
              }
            />
          }
          label={
            <Typography className={classes.questionOptions}
                        dangerouslySetInnerHTML={{__html:escapeHTMLPTags(`${option_index}. ${option}`)}}>
            </Typography>
          }
          checked={!!selectedOptions[option_index]?.selected}
        />
      );
    });

  useEffect(() => {
    if(!decodedToken){
      return;
    }

    setLoading(true);

    makeRequest({
      method: 'get',
      url: `${API_BASE_URL}/quiz/questions/${id}`,
      })
      .then(result => {
        setQuestions(result.data);
        return result.data;
      })
      .then((questions) => {
        makeRequest({
          method: 'get',
          url: `${API_BASE_URL}/quiz/${id}`,
          })
          .then(result => {
            const { correctAnswers,
              currentIndex,
              points,
              isResolved,
              solvedPercentage
            } = result.data;

            if(isResolved){
              setSolvedPercentage(solvedPercentage);
              setShowCongratulations(true);
              setShowExamSection(false);
            }

            const index = currentIndex >= questions?.length? questions.length - 1: currentIndex;
            setPoints(points);
            setCurrentIndex(index);
            setSolvedQuestions(correctAnswers);
            setPoints(points);
            setCorrectAnswers(questions[index].correctAnswers);
            setLoading(false);
          })
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [decodedToken]);

  useEffect(() => {
    if (!!questions.length) {
      setCorrectAnswers(questions[currentIndex].correctAnswers);
      setSelectedOptions({});
      setEvaluated(false);
      setShowExplanationBtn(false);
      setShowExplanation(false);
    }
  }, [questions, currentIndex]);

  // console.log("CUMMULATED POINTS: ", points);
  // console.log("SOLVED: ", solvedQuestions);
  // console.log("SINGLE ANSWER QUESTION: ", singleAnswerQuestions);
  // console.log("CORRECT ANSWERS: ", correctanswers);

  if (loading) {
    return <Loading />;
  }

  if (!loading && !questions.length) {
    return (
      <div className={classes.examination}>
        <Card className={classes.examinationCard}>
          <CardContent className={classes.newQuizContentWrapper}>
            <AnnouncementIcon className={classes.icon} />
            <Typography className={classes.newQuizTitle} align="center">
              Nu ai nici un test in desfășurare
            </Typography>
            <Typography className={classes.newQuizText} align="center">
              Pentru a crea un nou test te rugăm să selectezi capitolele din care vrei să compui noua grilă.
            </Typography>
            <div className={classes.newQuizWrapper}>
              <Button
                color="primary"
                variant="contained"
                className={classes.answerButton}
                onClick={() => history.push("/dashboard")}
              >
                crează grilă
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className={classes.examination}>
      <Congratulations
        visibility={showCongratulations}
        points={points}
        totalPoints={totalPoints}
        solvedPercentage={solvedPercentage}
        onClick={createNewQuiz}
      />
      <Results
        visibility={showCongratulations}
        questions={questions}
      />
      <Grow in={showExamSection} timeout={600}>
        <Card className={classes.examinationCard}>
          <CardContent className={classes.examinationCardContent}>
            <Typography className={classes.questionNo}>
              Intrebarea {currentIndex + 1} din {questions.length}
            </Typography>
            <Typography className={classes.mainQuestion} dangerouslySetInnerHTML={{__html: escapeOtherHTMLTags(questions[currentIndex].question)}}>
              {/*{questions[currentIndex].question}*/}
            </Typography>
            <div>
              <FormGroup>
                {generateOptions(questions[currentIndex].questionOptions)}
              </FormGroup>
            </div>
            <div
              className={classes.buttonWrapper}
              style={{
                justifyContent: showExplanationBtn
                  ? "space-between"
                  : "flex-end",
              }}
            >
              {showExplanationBtn ? (
                <Button
                  className={classes.explanationButton}
                  color="primary"
                  variant="contained"
                  onClick={() => setShowExplanation(!showExplanation)}
                >
                  {!showExplanation ? "Arată explicație" : "Ascunde explicație"}
                </Button>
              ) : null}

              <Button
                color="primary"
                variant="contained"
                className={classes.answerButton}
                onClick={handleEvaluateTest}
              >
                {!isEvaluated ? "Răspunde" : "Continuă"}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grow>

      <Card
        style={{ display: showExplanation ? "block" : "none" }}
        className={classes.explanationCard}
      >
        <CardContent className={classes.explanationContent}>
          <Typography className={classes.explanationText}>
            {questions[currentIndex].explanation}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default withFirebase(Examination);
