import React from "react";
import { makeStyles, Card, Grid, Divider, Typography } from "@material-ui/core";
import Grow from "@material-ui/core/Zoom";
import { calculateResultForQuestion, escapeHTMLPTags, escapeOtherHTMLTags } from "../../common/utils";

import { QUIZ_OPTIONS } from "../../common/constants";

import clsx from "clsx";

const useStyles = makeStyles(() => ({
  card: {
    width: "768px",
    marginBottom: "100px",
    "@media only screen and (max-width: 804px)":{
      width:"100%",
    }
  },
  resultWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
    padding: "24px",
  },
  gridContainer: {
    marginBottom: "24px",
    "&:not(:last-child)":{
      borderBottom: "1px solid rgba(0, 55, 106, 0.25)",
    }
  },
  title: {
    color: "rgb(107, 107, 107)",
    fontFamily: "Roboto",
    fontSize: "34px",
    fontWeight: "500",
    lineHeight: "24px",
    marginTop: "25px",
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    marginTop: "8px",
    marginBottom: "32px",
  },
  index:{
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
  },
  question: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    "& p, span":{
      margin: "0px",
      color: "rgba(0, 0, 0, 0.74) !important",
    },
    marginBottom: "8px"
  },
  selected: {
    color: "rgba(0, 0, 0, 0.74)",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginBottom: "8px"
  },
  result:{
    color: "#E02020",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "900",
    lineHeight: "24px",
    textAlign: "right",
  },
  passed:{
    color: "#56A3A6"
  },
  divider:{
    color:"rgb(0, 55, 106)",
  }
}));

const Results = ({
  visibility,
  questions,
  }) => {
  const classes = useStyles();

  const getQuestionOptions = options => {
    const optionsObj = options.reduce((accumulator, current, index) => {
      return {...accumulator, [QUIZ_OPTIONS[index]]: current}
    }, {});
    return optionsObj;
  };

  const renderOptions = (selectedAnswers, optionsObj) => {
    return selectedAnswers.map((answer,index) => {
      return  (<Typography key={index+answer} className={classes.question} dangerouslySetInnerHTML={{__html:escapeHTMLPTags(`${answer}. ${optionsObj[answer]}`)}}/>)
    });
  }

  const renderAnsweredQuestion = (question, index) => {
    const questionOptions = getQuestionOptions(question.questionOptions);
    const questionResult = calculateResultForQuestion(question);

    return <Grid container className={classes.gridContainer} key={question.questionId}>
      <Grid item xs={1} sm={1}>
        <Typography className={classes.index}>
          {++index}.
        </Typography>
      </Grid>
      <Grid item xs={9} sm={9}>
        <Typography className={classes.question} dangerouslySetInnerHTML={{__html: escapeOtherHTMLTags(question.question)}} />
        <Typography className={classes.selected}>
          Selectate:
        </Typography>
        {renderOptions(question.selectedAnswers, questionOptions)}
        <Typography className={classes.selected}>
          Corecte:
        </Typography>
        {renderOptions(question.correctAnswers, questionOptions)}
        <br/>
      </Grid>
      <Grid item xs={2} sm={2}>
        <Typography className={clsx(classes.result, {[classes.passed]: questionResult.isPassed })}>
          {questionResult.text}
        </Typography>
      </Grid>
      <Divider />
    </Grid>
  }

  if (!visibility) {
    return null;
  }

  return (
    <Grow in={visibility} timeout={600}>
      <Card className={classes.card}>
        <div className={classes.resultWrapper}>
          <Typography className={classes.title}>
            Corectarea grilei
          </Typography>
          <Typography className={classes.subtitle}>
            Aici poți vizializa răspunsurile date și variantele corecte
          </Typography>
          {questions.map(renderAnsweredQuestion)}
        </div>
      </Card>
    </Grow>
  );
};

export default Results;
