import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../Firebase";

import { Card, Grid, useMediaQuery, makeStyles, Typography } from "@material-ui/core";

import EmailStage from "./Components/EmailStage";
import PasswordStage from "./Components/PasswordStage";
import ForgotPassword from "./Components/ForgotPassword";
import RegisterStage from "./Components/RegisterStage";
import SubscriptionStage from "./Components/SubscriptionStage";

import { validationSchema } from "./validationSchema";
import validate from "validate.js";

import {
  FREE_TRIAL_PRICE,
  PREMIUM_SUBSCRIPTION_PRICE,
} from "../../common/constants";

const INITIAL_STATE = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
};

const useStyles = makeStyles(() => ({
  container:{
    display:"flex",
    flexDirection:"column",
    width: "100%",
    padding: "24px 20px 0px 20px",
    "@media only screen and (max-width: 430px)": {
      padding:"24px 5px 0px 5px"
    }
  },
  projectDetailsContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    marginBottom: "20px"
  },
  projectDetailsText:{
    marginLeft: "16px",
    fontFamily:"Roboto",
    fontSize:"12px",
    fontWeight: 400,
    color: "rgb(27, 48, 88)",
    lineHeight:1.5
  },
  projectLogo:{
    height: "56px"
  },
  signInWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "@media only screen and (max-width: 850px)": {
      justifyContent: "flex-start",
    },
  },
  card: {
    width: "788px",
    "@media only screen and (max-width: 863px)": {
      marginTop: "32px",
      marginBottom: "24px",
      width: "100%",
    },
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "@media only screen and (max-width: 850px)": {
      marginTop: "20px",
    },
  },
  logo: {
    height: "300px",
    "@media only screen and (max-width: 430px)": {
      height: "180px",
      marginLeft:"0px"
    },
  },
  loginWrapper: {
    margin: "32px 32px 32px 0",
    paddingLeft: "32px",
    borderLeft: "1px solid #00376A",
    "@media only screen and (max-width: 960px)": {
      borderLeft: "unset",
    },
    "@media only screen and (max-width: 850px)": {
      borderLeft: "unset",
    },
    "@media only screen and (max-width: 400px)": {
      margin: "unset",
      padding: "32px 5px 32px 5px",
      borderLeft: "unset",
    },
  },
  loginText: {
    color: "#6B6B6B",
    fontFamily: "Roboto",
    fontSize: "34px",
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "36px",
    textAlign: "center",
  },
  loginTextMarginBottom: {
    marginBottom: "56px",
  },
  passwordTextMarginBottom: {
    marginBottom: "56px",
  },
  registerTextMarginBottom: {
    marginBottom: "8px",
  },
  emailText: {
    color: "#AEAEAE",
    fontSize: "16px",
    lineHeight: "21px",
    marginBottom: "30px",
  },
  resetPasswordText: {
    cursor: "pointer",
    color: "rgb(255, 203, 0)",
    fontSize: "14px",
    lineHeight: "18px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  helperText: {
    color: "#000",
    fontSize: "14px",
    lineHeight: "18px",
  },
  checkIcon: {
    marginRight: "5px",
  },
  textField: {
    minHeight: "5rem",
    marginBottom: "10px",
    borderRadius: "4px",
  },
  signInButton: {
    height: "49px",
    lineHeight: "18px",
    marginBottom: "20px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
  doctorImg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "96px",
    marginBottom: "15px",
  },
  explanationContainer:{
    padding: "0px 20px 0px 20px"
  },
  explanationText: {
    marginTop: "70px",
    marginBottom: "40px",
    fontFamily:"Roboto",
    fontSize: "16px",
    fontWeight: 400,
    color: "rgb(27, 48, 88)",
    maxWidth: "1000px"
  }
}));

validate.options = {
  fullMessages: false,
};

const SignIn = ({ history, firebase }) => {
  const classes = useStyles();
  const matches600 = useMediaQuery("(max-width:800px)");

  const [formState, setFormState] = useState(INITIAL_STATE);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [uid, setUid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);


  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleCheckEmail = () => {
    const { email } = formState.values;
    const errors = validate(formState.values, validationSchema);

    if (errors?.email?.length) {
      setFormState((formState) => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {},
      }));
      return;
    }

    firebase
      .checkIfEmailExists(email)
      .then((result) => {
        if (!!result.length) {
          setStage(1);
        } else {
          setStage(2);
        }
      })
      .catch((error) => console.error("Error: ", error));
  };

  const handleSignIn = (event) => {
    const { email, password } = formState.values;
    if (!password?.length) {
      return;
    }

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setFormState({ ...INITIAL_STATE });
        history.push("/dashboard");
      })
      .catch((error) => {
        setError({ ...error });
      });
    event.preventDefault();
  };

  const handleResetPassword = () => {
    firebase.auth
      .sendPasswordResetEmail(formState.values.email)
      .then(() => {
        console.log("Reset password email sent");
        setStage(4);
      })
      .catch((error) => console.log("error: ", error));
  };

  const handleSignUp = (event) => {
    const { email, password, firstName, lastName } = formState.values;

    firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then((result) => {
        const { uid } = result.user;
        setUid(uid);
        firebase.updateProfile(firstName,lastName);
        firebase.setUserDetailsInDatabase(uid, firstName, lastName, email);
      })
      .then(() => {
          history.push("/");
      })
      .catch((error) => {
        console.log("Error: ", error);
        setError({ ...error });
      });

    event.preventDefault();
  };

  const handleBuySubscription = () => {
    setLoading(true);
    if (selectedOffer === FREE_TRIAL_PRICE) {
      const startTrial = firebase.functions.httpsCallable("setFreeTrial");
      return startTrial({
        uid,
      })
        .then((result) => {
          history.push("/");
        })
        .catch((error) => console.error("Error: ", error));
    }
    if (selectedOffer === PREMIUM_SUBSCRIPTION_PRICE) {
      const getEuPlatescUrl = firebase.functions.httpsCallable("getPaymentUrl");
      return getEuPlatescUrl({
        uid,
        amount: selectedOffer,
        fname: formState.values.firstName,
        lname: formState.values.lastName,
        email: formState.values.email,
      }).then((result) => {
        window.location.href = result.data;
      });
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    const errors = validate(formState.values, validationSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    setError(null);
  };

  const renderLoginPage = (stage) => {
    switch (stage) {
      case 1:
        return (
          <PasswordStage
            classes={classes}
            error={error}
            hasError={hasError}
            formState={formState}
            handleChange={handleChange}
            handleCheckEmail={handleCheckEmail}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleResetPassword={handleResetPassword}
            handleSignIn={handleSignIn}
          />
        );
      case 2:
        return (
          <RegisterStage
            classes={classes}
            hasError={hasError}
            formState={formState}
            handleChange={handleChange}
            showPassword={showPassword}
            showConfirmPassword={showConfirmPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleClickShowConfirmPassword={handleClickShowConfirmPassword}
            handleSignUp={handleSignUp}
          />
        );
      case 3:
        return (
          <SubscriptionStage
            classes={classes}
            hasError={hasError}
            selectedOffer={selectedOffer}
            setSelectedOffer={setSelectedOffer}
            formState={formState}
            handleChange={handleChange}
            handleBuySubscription={handleBuySubscription}
            loading={loading}
          />
        );
      case 4:
        return (
          <ForgotPassword
            classes={classes}
            formState={formState}
            setStage={setStage}
          />
        );
      default:
        return (
          <EmailStage
            classes={classes}
            hasError={hasError}
            formState={formState}
            handleChange={handleChange}
            handleCheckEmail={handleCheckEmail}
          />
        );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.projectDetailsContainer}>
        <img src="/images/uefiscdi-logo.svg" alt="Uefiscdi Logo" className={classes.projectLogo}/>
        <Typography className={classes.projectDetailsText} noWrap>
          Proiect finanțat prin programul FDI 2022<br/>
          Cod proiect: CNFIS-FDI-2022-0382
        </Typography>
      </div>
    <div className={classes.signInWrapper}>
      <Card className={classes.card}>
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <div className={classes.logoWrapper}>
              <img src="/images/umft-logo.png" className={classes.logo}/>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.loginWrapper}>{renderLoginPage(stage)}</div>
          </Grid>
        </Grid>
      </Card>
      <div className={classes.explanationContainer}>
        <Typography className={classes.explanationText} align="center">
          Platformă online dedicată viitorilor candidați în vederea pregătirii pentru examenul de admitere la facultatea de
          Medicină și cea de Medicină Dentară din cadrul Universității de Medicină și Farmacie "Victor Babeș" din Timișoara.
          <br/><br/>
          Baza de date inclusă în această platformă cuprinde teste tip grilă din bibliografia materiilor Biologie și Chimie destinată admiterii din anul 2022, urmând să fie înlocuite cu noile grile cuprinse în bibliografia anului 2023 în momentul în care acestea vor fi disponibile.
        </Typography>
      </div>
    </div>
    </div>
  );
};

export default withRouter(withFirebase(SignIn));
