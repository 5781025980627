import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, Grow, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    width: "788px",
    minHeight: "190px",
    marginTop: "40px",
    alignItems: "center",
    paddingBottom: "12px",
    paddingTop: "12px",
    "@media only screen and (max-width: 804px)":{
      flexDirection: "column",
      width:"100%",
    }
  },
  message: {
    color: "rgba(0,0,0,0.87)",
    width: "360px",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginLeft: "18px",
  },
  secondStatsSection: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginLeft: "49px",
    "@media only screen and (max-width: 804px)":{
      marginTop: "16px"
    }
  },
  quizzesDone: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "20px",
    marginBottom: "6px",
  },
  accumulatedPoints: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "34px",
    letterSpacing: 0,
    lineHeight: "36px",
    marginBottom: "6px",
  },
}));

const StatsCard = ({
  firstName,
  quizzesDone,
  quizzesPassed,
  accumulatedPoints,
}) => {
  const classes = useStyles();
  return (
    <Grow in={true} timeout={600}>
      <Card className={classes.card}>
        <Typography className={classes.message}>
          {firstName}, ai rezolvat {quizzesDone} {quizzesDone < 20 ? "" : "de"}{" "}
          teste, ai trecut {quizzesPassed} din ele și ai acumulat un total de{" "}
          {accumulatedPoints} de puncte.
        </Typography>
        <div className={classes.secondStatsSection}>
          <Typography className={classes.quizzesDone}>
            {quizzesDone < 20 ? quizzesDone : quizzesDone + " de"} teste
            completate
          </Typography>
          <Typography className={classes.accumulatedPoints}>
            {accumulatedPoints < 20
              ? accumulatedPoints
              : accumulatedPoints + " de"}{" "}
            puncte
          </Typography>
          <Typography className={classes.quizzesDone}>
            {quizzesPassed < 20 ? quizzesPassed : quizzesPassed + " de"} teste
            completate cu success
          </Typography>
        </div>
      </Card>
    </Grow>
  );
};

export default StatsCard;
