import React from "react";
import {
  makeStyles,
  Typography,
  Card,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button, Switch
} from "@material-ui/core";
import clsx from "clsx";

import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  card: {
    // height: "235px",
    width: "304px",
    padding: "16px",
    margin:"0 16px 16px",
    backgroundColor:"#2B3058"
  },
  absolute:{
    position: "absolute",
    top: "300px",
    right: "16px",
  },
  message: {
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginBottom: "20px",
  },
  timerContainer:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center'
  },
  timerText:{
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },
  textField: {
    width: "100%",
    marginBottom: "34px",
  },
  formControl: {
    width: "100%",
    marginBottom: "34px",
    color: "#FFFFFF !important",
  },
  select: {
    color: "#FFFFFF",
    "& .MuiOutlinedInput-notchedOutline":{
      borderColor: "#F8F8F8 !important"
    }
  },
  root: {
    color: "#F8F8F8",
    borderColor: "#F8F8F8"
  },
  icon: {
    fill: "#FFFFFF",
  },
  inputLabel:{
    color: "#FFFFFF !important",
  },
  button: {
    borderRadius: "4px",
    backgroundColor: "#FFCB00",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    width: "100%",
    height: "49px",
    border: "unset",
    color: "#1B3058",
    "&:hover": {
      backgroundColor: "rgba(255, 153, 98, 1)",
    },
  },
  loading: {
    color: "#FFFFFF",
  },
}));

const QuizSelectQuestionsNumber = ({
  visibility,
  quizQuestionsNo,
  setQuizQuestionNo,
                                     timerValue,
                                     setTimerValue,
                                     isWithTimer,
                                     setIsWithTimer,
  onClick,
  isButtonDisabled,
  absolute,
}) => {
  const classes = useStyles();

  const handleSwitch = event => setIsWithTimer(event.target.checked);

  if (!visibility) {
    return null;
  }

  return (
    <Card className={clsx(classes.card, {[classes.absolute]: absolute})}>
      <Typography className={classes.message}>
        Selectează numărul de întrebări și dă-i click pe butonul de compune test
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel className={classes.inputLabel} id="select-outlined-label">Numarul de întrebări</InputLabel>
        <Select
           className={classes.select}
           inputProps={{
               classes: {
                   root: classes.root,
                   icon: classes.icon
               },
           }}
          labelId="select-outlined-label"
          id="select-outlined"
          label="Numarul de întrebări"
          value={quizQuestionsNo}
          onChange={(event) => setQuizQuestionNo(event.target.value)}
          variant="outlined"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={120}>120</MenuItem>
          <MenuItem value={180}>180</MenuItem>
        </Select>
      </FormControl>
      {/*<div className={classes.timerContainer}>*/}
      {/*  <Typography className={classes.timerText}>Contra cronometru?</Typography>*/}
      {/*  <Switch*/}
      {/*    checked={isWithTimer}*/}
      {/*    onChange={handleSwitch}*/}
      {/*    name="Timer"*/}
      {/*  />*/}
      {/*</div>*/}


      <Button
        className={classes.button}
        variant="outlined"
        onClick={onClick}
        disabled={isButtonDisabled}
      >
        {isButtonDisabled ? (
          <CircularProgress className={classes.loading} />
        ) : (
          "COMPUNE GRILĂ"
        )}
      </Button>
    </Card>
  );
};

export default QuizSelectQuestionsNumber;
