import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyle = makeStyles((theme) => ({
  forgotPasswordWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkIcon: {
    height: "100px",
    width: "100px",
    color: "#FF9962",
    marginBottom: "30px",
  },
  message: {
    fontSize: "16px",
    color: "#FF9962",
  },
  backButton: {
    height: "49px",
    lineHeight: "18px",
    marginTop: "30px",
    borderRadius: "4px",
    backgroundColor: "#00376A",
  },
}));

const ForgotPassword = ({ formState, setStage }) => {
  const classes = useStyle();

  return (
    <div className={classes.forgotPasswordWrapper}>
      <CheckCircleOutlineIcon className={classes.checkIcon} />
      <Typography align="center" className={classes.message}>
        Am trimis un email cu link-ul de resetare a parolei la adresa{" "}
        {formState.values.email}
      </Typography>
      <Button
        className={classes.backButton}
        color="primary"
        variant="contained"
        fullWidth
        onClick={() => setStage(0)}
      >
        Du-ma la login
      </Button>
    </div>
  );
};

export default ForgotPassword;
