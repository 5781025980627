import React from "react";
import { makeStyles, Card, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ArrowButton } from "../Dashboard/components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width:"100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems:"center",
    width: "976px",
    "@media only screen and (max-width: 1200px)":{
      width:"100%",
    }
  },
  elementWrapper: {
    display:"flex",
    flexDirection:"column",
    padding: "34px 40px 0px 40px"
  },
  card:{
    display:"flex",
    flexDirection:"column",
    width: "75%",
    // marginTop: "80px",
    margin:"80px 16px 0px 16px",
    backgroundColor: "rgb(43, 48, 88)",
    "@media only screen and (max-width: 800px)":{
      width:"100%"
    }
  },
  cardContent:{
    padding: "24px 16px 32px 16px",
  },
  image:{
    height:"168px"
  },
  title:{
    fontFamily: "Roboto",
    color: "rgb(255, 255, 255)",
    fontSize: "48px",
    fontWeight: 500,
    lineHeight: "56px",
    marginBottom: "16px",
  },
  description:{
    fontFamily: "Roboto",
    color: "rgb(255, 255, 255)",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
  },
  buttonContainer:{
    display: "flex",
    justifyContent:"flex-start",
    width:"100%",
    marginTop: "50px"
  },
  customArrowButtons:{
    marginLeft: "16px",
    padding: 0,
    height: "48px",
    width: "128px",
    backgroundColor: "#2B3058",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    borderRadius: "30px",
    "&:hover":{
      backgroundColor: "#2B3058"
    }
  },
  divider:{
    textAlign:"center",
    height: "2px",
    backgroundColor: "rgb(27, 48, 88)",
    margin:"auto",
    minWidth: "480px",

  },
}));

const Contact = ({firebase}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <ArrowButton position="left"  customStyles={classes.customArrowButtons} text="Back" onClick={() => history.push("/")} />
        </div>
        <Card className={classes.card}>
          <img src="/images/universitate.jpg" className={classes.image} />
          <div className={classes.cardContent}>
            <Typography className={classes.title}>Contact</Typography>
            <Typography className={classes.description}>Adresa: Piaţa Eftimie Murgu Nr. 2, 300041, Timişoara</Typography>
            <Typography className={classes.description}>Telefon: 0256/204400, 0256/204250</Typography>
            <Typography className={classes.description}>Fax: 0256/490626</Typography>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Contact;
