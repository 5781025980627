import React, { createContext, useContext, useMemo } from "react";

const FilterGroupContext = createContext({});

const FilterGroup = (props) => {
  const contextValue = useMemo(
    () => ({
      getSelectedChapter: (filterName) => props.value[filterName],

      selectChapter: (filterName, value) => {
        const newValue = { ...props.value, [filterName]: value };
        props.setValue(newValue);
      },

      removeChapter: (id) => props.removeChapter(id),

      getSelectedSubChapter: (chapterId, subChapterId) => {
        return props.value[chapterId].find(
          (element) => element === subChapterId
        );
      },

      selectSubChapter: (chapterId, subChapterId) => {
        props.setValue({
          ...props.value,
          [chapterId]: [...props.value[chapterId], subChapterId],
        });
      },

      removeSubChapter: (chapterId, subChapterId) => {
        const newSubChapters = [...props.value[chapterId]];
        const index = newSubChapters.indexOf(subChapterId);

        if (index > -1) {
          newSubChapters.splice(index, 1);
        }

        props.setValue({ ...props.value, [chapterId]: newSubChapters });
      },
    }),

    [props]
  );
  return (
    <FilterGroupContext.Provider value={contextValue}>
      {props.children}
    </FilterGroupContext.Provider>
  );
};

const useChapterValue = (filterName, defaultValue) => {
  const filterGroup = useContext(FilterGroupContext);

  let selectedChapter = filterGroup.getSelectedChapter(filterName);

  if (selectedChapter === undefined) {
    selectedChapter = defaultValue;
  }

  const selectChapter = (value) => filterGroup.selectChapter(filterName, value);
  const removeChapter = (id) => filterGroup.removeChapter(id);

  return [selectedChapter, selectChapter, removeChapter];
};

const useSubChapterValue = (chapterId, subChapterId, defaultValue) => {
  const filterGroup = useContext(FilterGroupContext);

  let selectedSubChapter = filterGroup.getSelectedSubChapter(
    chapterId,
    subChapterId
  );

  if (selectedSubChapter === undefined) {
    selectedSubChapter = defaultValue;
  }

  const selectSubChapter = (subChapterId) =>
    filterGroup.selectSubChapter(chapterId, subChapterId);

  const removeSubChapter = (subChapterId) =>
    filterGroup.removeSubChapter(chapterId, subChapterId);

  return [selectedSubChapter, selectSubChapter, removeSubChapter];
};

export { FilterGroup, useChapterValue, useSubChapterValue };
