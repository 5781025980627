import React from "react";
import { makeStyles } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1,
  },
  loading: {
    color: "#FFCB00",
    zIndex: 2,
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <Backdrop invisible={true} open={true} className={classes.backdrop}>
      <CircularProgress className={classes.loading} />
    </Backdrop>
  );
};

export default Loading;
