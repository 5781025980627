import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import SubjectCard from "../SubjectCard";
import { DashboardCard } from "../../../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection:"column"
  },
  subjectsList:{
    display:"flex",
    flexDirection:"row",
    flexWrap: "wrap",
    justifyContent:"left",
    gap: "13px"
  },
  buttonsList:{
    display:"flex",
    flexDirection:"row",
    flexWrap: "wrap",
    justifyContent:"left",
    width: "100%",
  }
}));

const SubjectSelectSection = ({ subjects, isSelectMode }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
    <div className={classes.subjectsList}>
      {subjects.map((subject, index) => (
        <SubjectCard
          key={index}
          id={subject.id}
          title={subject.title}
          subtitle={subject.chapters}
          cardBackgroundColor={subject.backgroundColor}
          cardBackgroundUrl={subject.backgroundUrl}
          isSelectMode={isSelectMode}
          order={subject.order}
        />
        ))}
    </div>
      <div className={classes.buttonsList}>
        <DashboardCard title="Noutăți despre admitere" onClick={() => history.push("/news")} order={4}/>
        <DashboardCard title="Tips & Tricks" onClick={() => history.push("/tips-and-tricks")} order={5}/>
        <DashboardCard title="FAQ" onClick={() => history.push("/faq")} order={6}/>
      </div>

    </div>);
};

export default SubjectSelectSection;
