import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/firestore";
import axios from "axios";
import {API_BASE_URL} from "../common/constants";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};
class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();
    this.functions = app.functions();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  checkIfEmailExists = (email) => this.auth.fetchSignInMethodsForEmail(email);

  createCredential = (email, password) =>
    app.auth.EmailAuthProvider.credential(email, password);

  doSignOut = () => this.auth.signOut();

  // *** User API ***//

  updateProfile = (firstName, lastName) =>
    this.auth.currentUser.updateProfile({
      displayName: JSON.stringify({firstName,lastName}),
    });

  setUserInSecondDB = (token, firstName, lastName) =>  
    axios.post(API_BASE_URL + '/users/me', {
    firstName,
    lastName,
  },{headers: {Authorization: `Bearer ${token}`}});

  setUserDetailsInDatabase = (uid, firstName, lastName, email) =>
    this.db.ref("users/" + uid).set({
      firstName,
      lastName,
      email,
      accumulatedPoints: 0,
      quizzesDone: 0,
      quizzesPassed: 0,
    });

  updateUserName = (uid, firstName, lastName) =>
    this.db.ref("users/" + uid).update({
      firstName,
      lastName,
    });

  getUserDetailsFromDatabase = (uid) =>
    this.db.ref("users/" + uid).once("value");

  getQuizz = (uid) => this.db.ref("quizzes/" + uid).once("value");

  setQuizzDetails = (
    uid,
    currentIndex,
    solvedQuestions,
    singleAnswerQuestions,
    points
  ) =>
    this.db.ref("quizzes/" + uid).update({
      currentIndex,
      solvedQuestions,
      singleAnswerQuestions,
      points,
    });

  // *** Database API ***//

  getSubscription = (uid) =>
    this.firestore
      .collection("subscriptions")
      .doc(uid)
      .get();

  saveQuestion = (questionObj) =>
    this.firestore.collection("questions").add(questionObj);

  userAccountListener = (uid, callback) =>
    this.db.ref("users/" + uid).on("value", callback);

  removeUserAccountListener = (uid) => this.db.ref("users/" + uid).off();

  getChaptersFromDatabase = () => this.db.ref("chapters").once("value");
}

export default Firebase;
