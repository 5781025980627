import React, {useState} from "react";
import { makeStyles } from "@material-ui/styles";
import { Topbar,Sidebar, Footer } from "./components";

const useStyles = makeStyles(() => ({
  mainWrapper: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  content: {
    flex: 1,
    paddingTop: 56,
    marginBottom: "100px",
  },
}));

const Main = ({ children }) => {
  const classes = useStyles();
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };


  return (
    <div className={classes.mainWrapper}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant="temporary"
      />
      <main className={classes.content}>{children}</main>
      <Footer />
    </div>
  );
};

export default Main;
