import React from 'react';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';

const PrivateRouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} {...rest}/>
        </Layout>
      )}
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default compose(withFirebase, withAuthorization)(PrivateRouteWithLayout);
