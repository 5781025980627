export const FREE_TRIAL_PRICE = 0;
export const PREMIUM_SUBSCRIPTION_PRICE = 60; //50

export const QUIZ_OPTIONS = ["A", "B", "C", "D", "E"];
export const QUIZZES_PAGE_SIZE = 10;

export const API_BASE_URL="https://trainingadmitere.umft.ro/api";
// export const API_BASE_URL="http://localhost:8080";

export const ALLOWED_EMAILS = ["alexandru@aliandev.com", "arijana@aliandev.com"]

