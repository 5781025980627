import React from "react";
import {
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Grid
} from "@material-ui/core";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const DetailsSection = ({
  classes,
  handleChange,
  handleUpdate,
  isBtnDisabled,
  handleClickShowPassword,
  handleClickShowConfirmPassword,
  showConfirmPassword,
  showPassword,
  formState,
}) => {
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.account}>
      <Typography className={classes.heading}>Contul tău</Typography>
      <Typography className={classes.subHeading}>
        {formState.values.email}
      </Typography>
      <div className={classes.accountForm}>
      <Grid
        container spacing={2}
        >
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textField}
            error={hasError("firstName")}
            fullWidth
            helperText={
              hasError("firstName") ? formState.errors.firstName[0] : null
            }
            name="firstName"
            label="Prenume"
            onChange={handleChange}
            type="text"
            value={formState.values.firstName}
            variant="outlined"
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
        className={classes.textField}
        error={hasError("currentPassword")}
        fullWidth
        helperText={
          hasError("currentPassword")
            ? formState.errors.currentPassword[0]
            : null
        }
        name="currentPassword"
        label="Parolă curentă"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={!showPassword ? "password" : "text"}
        value={formState.values.currentPassword}
        variant="outlined"
      />
        </Grid>
        
        <Grid item xs={12} sm={6}>
        <TextField
              className={classes.textField}
              error={hasError("lastName")}
              fullWidth
              helperText={hasError("lastName") ? formState.errors.lastName[0] : null}
              name="lastName"
              label="Nume"
              onChange={handleChange}
              type="text"
              value={formState.values.lastName}
              variant="outlined"
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
        className={classes.textField}
        error={hasError("newPassword")}
        fullWidth
        helperText={
          hasError("newPassword") ? formState.errors.newPassword[0] : null
        }
        name="newPassword"
        label="Parolă Nouă"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        type={!showConfirmPassword ? "password" : "text"}
        value={formState.values.newPassword}
        variant="outlined"
      />
            </Grid>
            <Grid container item justifyContent="center" xs={12} sm={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.updateBtn}
              onClick={handleUpdate}
              disabled={isBtnDisabled}
            >
              UPDATE
            </Button>
          </Grid>
        </Grid>
        </div>
    </div>
  );
};

export default DetailsSection;
